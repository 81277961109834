import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../types/Project";

type ProjectGridItemProps = {
  project: Project;
  deleteProject: (id: string) => void;
  handleCopy: (script: string) => void;
};

const ProjectGridItem: React.FC<ProjectGridItemProps> = ({
  project,
  deleteProject,
  handleCopy,
}) => {
  const navigate = useNavigate();
  const [showItems, setShowItems] = useState(false);

  return (
    <div
      className="relative rounded-lg p-2 hover:bg-gray-50 flex flex-col gap-2 h-full w-full group"
      onClick={() => navigate(`/projects/${project.id}`)}
      onMouseLeave={() => setShowItems(false)}
    >
      <Menu
        as="div"
        className="absolute top-0 right-0 inline-block"
        onClick={(e) => e.stopPropagation()}
      >
        <MenuButton
          className="absolute right-4 top-4 w-8 h-8 bg-gray-300 shadow-sm rounded-lg cursor-pointer hidden group-hover:block "
          onClick={(e) => {
            e.stopPropagation(), setShowItems((prev) => !prev);
          }}
        >
          <EllipsisHorizontalIcon className="stroke-gray-500" />
        </MenuButton>
        {showItems && (
          <ul
            className="absolute cursor-pointer right-0 bottom-full z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="py-1">
              <li>
                <div
                  onClick={() => navigate(`/projects/${project.id}`)}
                  className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <PencilSquareIcon
                    aria-hidden="true"
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Edit
                </div>
              </li>
              <li>
                <div
                  onClick={(e) => {
                    e.stopPropagation(), handleCopy(project.script);
                  }}
                  className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <DocumentDuplicateIcon
                    aria-hidden="true"
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Copy script
                </div>
              </li>
            </div>
            <div className="py-1">
              <li>
                <div
                  onClick={(e) => {
                    e.stopPropagation(), deleteProject(project.id);
                  }}
                  className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <TrashIcon
                    aria-hidden="true"
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Delete
                </div>
              </li>
            </div>
          </ul>
        )}
      </Menu>

      <div className="bg-gray-100 border aspect-video w-full overflow-hidden rounded-lg py-4 flex justify-center">
        <div className=" h-full overflow-hidden p-4 text-[1%] w-2/5 bg-white text-center shadow-md">
          <h2 className="text-[7%] font-semibold mb-1">{project.name}</h2>
          <span className=" text-[1px]">{project.script}</span>
        </div>
      </div>
      <div className="w-full">
        <p className="font-bold">{project.name}</p>
        <p className="text-sm text-gray-500">{project.topic}</p>
      </div>
    </div>
  );
};

export default ProjectGridItem;
