import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { PlayerConfig, PlayerInfo } from "../hooks/usePlayer";
import { VoiceoverStatus } from "../views/Project";
import LoadingLogo from "./LoadingLogo";
import SoundWaveProgressBar from "./SoundWaveProgressBar";

type VoiceoverPlayerDisplayProps = {
  title: string;
  status: VoiceoverStatus;
  onClick: () => void;
  playerInfo: PlayerInfo;
  playerConfig: PlayerConfig;
};

export const VoiceoverPlayerDisplay: React.FC<VoiceoverPlayerDisplayProps> = ({
  title,
  status,
  onClick,
  playerInfo,
  playerConfig,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleClick = () => {
    if (status === "idle") {
      onClick();
    }
  };
  return (
    <motion.div
      onClick={handleClick}
      className="z-20 py-4 px-6 shadow-violet-400/70 bg-white text-transparent text-2xl font-bold font-figTree relative overflow-hidden cursor-pointer"
      style={{ boxShadow: "0px 5px 20px 0px rgb(167 139 250 / 0.7)" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      variants={variants}
      initial="idle"
      animate={
        status === "loading"
          ? "loading"
          : status === "success"
            ? "player"
            : "idle"
      }
    >
      {title}
      <motion.div
        className="text-gray-500 absolute w-full h-full flex items-center justify-center left-0 pointer-events-none top-0"
        variants={textVariants}
        initial="idle"
        animate={status !== "idle" ? "loading" : "idle"}
      >
        {title}
      </motion.div>
      <motion.div
        className="absolute top-0 left-0 h-full w-full"
        variants={hoverEffectVariants}
        initial="default"
        animate={status === "idle" && isHovering ? "hovering" : "default"}
      >
        <div className="h-full w-[5px] bg-gradient-to-r from-violet-200 via-violet-500 to-violet-200 blur-sm"></div>
      </motion.div>
      {status === "loading" && (
        <motion.div
          className="absolute w-full h-full left-0 top-0 flex justify-center items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          <LoadingLogo width="w-16" />
        </motion.div>
      )}
      {status === "success" && (
        <div className="absolute w-full h-full inset-0 flex justify-between items-center px-2">
          <motion.div
            onClick={playerConfig.playPause}
            className="w-10 text-gray-500"
            initial={{ x: -40, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.2 }}
          >
            {playerInfo.playing ? <PauseIcon /> : <PlayIcon />}
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.55, duration: 0.3 }}
          >
            <SoundWaveProgressBar
              percentage={
                (Math.floor(playerInfo.progress.playedSeconds) /
                  playerInfo.duration) *
                100
              }
              playerConfig={playerConfig}
            />
          </motion.div>
          <motion.div
            className="text-xs text-gray-500 w-8"
            initial={{ x: -40, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.2 }}
          >
            {" "}
            {Math.floor(playerInfo.progress.playedSeconds)}:
            {Math.floor(playerInfo.duration)}
          </motion.div>
        </div>
      )}
    </motion.div>
  );
};

const textVariants = {
  idle: {
    scaleX: 1,
    opacity: 1,
  },
  loading: {
    scaleX: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const variants = {
  idle: {
    width: "18rem",
    height: "3.5rem",
    borderRadius: "0.5rem",
    y: 0,
  },
  loading: {
    width: "6rem",
    height: ["3.5rem", "6rem"],
    borderRadius: "2rem",
    y: 30,
    transition: {
      duration: 0.4,
      delay: 0.35,
      ease: "easeInOut",
    },
  },
  player: {
    width: "18rem",
    height: "4rem",
    borderRadius: "0.5rem",
    y: 0,
  },
};

const hoverEffectVariants = {
  hovering: {
    x: ["0%", "100%", "0%"],
    opacity: [0.6, 1],
    transition: { duration: 1.5, repeat: Infinity, ease: "easeInOut" },
  },
  default: {
    x: "0%",
    opacity: 0,
    transition: { opacity: { duration: 0.1 }, x: { duration: 1 } },
  },
};
