import React, { useCallback, useEffect, useRef, useState } from "react";
import { PlayerConfig } from "../hooks/usePlayer";

interface SoundWaveProgressBarProps {
  percentage: number;
  playerConfig: PlayerConfig;
}

const SoundWaveProgressBar: React.FC<SoundWaveProgressBarProps> = ({
  percentage,
  playerConfig,
}) => {
  const heights = [
    5, 6, 13, 20, 21, 15, 14, 10, 15, 17, 28, 27, 20, 14, 12, 5, 8, 10, 5,
  ];

  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setIsDragging(true);
      playerConfig.handleSeekStart();
      handleSeek(e);
    },
    [playerConfig.handleSeekStart],
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        handleSeek(e as unknown as React.MouseEvent);
      }
    },
    [isDragging],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    playerConfig.handleSeekEnd();
  }, [isDragging]);

  const handleSeek = (e: React.MouseEvent) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newProgress = Math.min(Math.max(offsetX / rect.width, 0), 1);

    playerConfig.handleSeek(newProgress);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const bars = heights.map((height, i) => {
    const isActive = i < (percentage / 100) * heights.length;

    return (
      <div
        key={i}
        className={`w-1 mx-0.5 ${
          isActive ? "bg-gray-500" : "bg-gray-300"
        } transition-colors duration-200 ease-in-out rounded-md`}
        style={{ height: `${height * 2.5}%` }}
      ></div>
    );
  });

  return (
    <div
      className="flex items-center h-16 w-full overflow-hidden"
      ref={containerRef}
      onMouseDown={handleMouseDown}
    >
      {bars}
    </div>
  );
};

export default SoundWaveProgressBar;
