import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

type BooleanRadioProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  form: UseFormReturn<TFieldValues>;
  options: Array<{ bool: boolean; label: string }>;
};

export const BooleanRadio = <T extends FieldValues>({
  name,
  form,
  options,
}: BooleanRadioProps<T>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { onChange, value } }) => (
        <div className="mt-6 space-y-6">
          {options.map((option) => (
            <div key={option.label} className="flex items-center">
              <input
                checked={option.bool === value}
                id={option.label}
                name={option.label}
                onChange={() => onChange(option.bool)}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor={option.label}
                className="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )}
    />
  );
};
