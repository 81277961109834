import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  GoogleButton,
  PrimaryButton,
  SecondaryButton,
} from "../components/Buttons";
import Layout from "../components/Layout";
import PasswordField from "../components/PasswordField";
import TextField from "../components/TextField";
import Warnings from "../components/Warnings";
import { ErrorWarning, useWarnings } from "../hooks/useWarnings";
import { useAuth } from "../provider/authProvider";
import { UserType } from "../types";
import { ErrorCode } from "../types/StatusCode";
import { callAPI, ControlledError } from "../utils/apiService";

const schema = yup.object().shape({
  email: yup.string().email().required("Email cannot be empty."),
  password: yup.string().optional(),
});

type FormFields = yup.InferType<typeof schema>;

type SignupResponse = {
  token: string;
  type: UserType;
};

enum SignupState {
  Emailprompt,
  GuestPrompt,
  PasswordPrompt,
}

export default function Startpage() {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const { warnings, pushWarning, clearWarnings, removeWarning } = useWarnings();
  const [signupState, setSignupState] = useState<SignupState>(
    SignupState.Emailprompt,
  );

  const form = useForm<FormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(schema),
  });

  const loginMutation = useMutation({
    mutationFn: ({ email, password }: FormFields) =>
      callAPI<SignupResponse>("/users/login", { email, password }),
    onSuccess: (response) => {
      setToken(response.token);

      if (response.type === UserType.DEFAULT) {
        navigate("/projects");
      } else {
        setSignupState(SignupState.GuestPrompt);
      }
    },
    onError: (err: Error) => {
      if (err instanceof ControlledError) {
        if (err.status === ErrorCode.PRECONDITION_REQUIRED) {
          setSignupState(SignupState.PasswordPrompt);
        }
        if (err.status === ErrorCode.NO_RESULT) {
          pushWarning(new ErrorWarning(err.message));
        }
      }
    },
  });

  const handleLogin = ({ email, password }: FormFields) => {
    clearWarnings();
    loginMutation.mutate({ email, password });
  };

  return (
    <Layout>
      <Dialog className="relative z-50" open={true} onClose={() => {}}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden w-full rounded-lg bg-white px-8 pb-8 pt-9 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:max-w-lg sm:py-10 sm:px-0 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="flex min-h-full flex-1 flex-col justify-center">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                  <div className="mx-auto h-12 w-12">
                    <img src="/shadow-logo.png" alt="logo" />
                  </div>
                  <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Enter your email to continue
                  </h2>

                  <p className="text-sm text-gray-600 text-center px-2 lg:px-14 mt-2">
                    If you already have an account, the password panel wil
                    appear automatically.
                  </p>
                </div>

                <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <div className="bg-white px-0 py-6 sm:px-12">
                    <Form onSubmit={form.handleSubmit(handleLogin)}>
                      <div className="space-y-6">
                        <TextField
                          form={form}
                          name="email"
                          key="email"
                          title="Email"
                        />

                        {signupState !== SignupState.PasswordPrompt ? null : (
                          <PasswordField
                            form={form}
                            name="password"
                            key="password"
                            title="Password"
                          />
                        )}

                        {signupState !== SignupState.Emailprompt ? null : (
                          <PrimaryButton type="submit" title="Continue" />
                        )}

                        {signupState !== SignupState.PasswordPrompt ? null : (
                          <PrimaryButton type="submit" title="Login" />
                        )}
                        {signupState !== SignupState.GuestPrompt ? null : (
                          <div className="flex gap-4">
                            <PrimaryButton
                              title="Create account"
                              onPress={() =>
                                navigate("/projects?completeAccount=true")
                              }
                            />
                            <SecondaryButton
                              title="Login as guest"
                              onPress={() => navigate("/projects")}
                            />
                          </div>
                        )}
                      </div>
                    </Form>

                    <div className="relative mt-10">
                      <div
                        aria-hidden="true"
                        className="absolute inset-0 flex items-center"
                      >
                        <div className="w-full border-t border-gray-200" />
                      </div>
                      <div className="relative flex justify-center text-sm font-medium leading-6">
                        <span className="bg-white px-6 text-gray-900">
                          Or continue with
                        </span>
                      </div>
                    </div>

                    <div className="mt-6">
                      <GoogleButton />
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
        <Warnings
          list={warnings}
          onClose={(item) => removeWarning(item)}
          hasSidebar={false}
        />
      </Dialog>
    </Layout>
  );
}
