import { PauseIcon, PlayIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import usePlayer from "../hooks/usePlayer";
import { VoiceItem } from "../types/Project";
import { sleep } from "../utils/functions";
import CircularProgress, { StaticCircularProgress } from "./CircularProgress";

type VoiceModalProps = {
  active: boolean;
  voices: VoiceItem[];
  deactivate: () => void;
  selectedVoice?: VoiceItem;
  setSelectedVoice: React.Dispatch<React.SetStateAction<VoiceItem | undefined>>;
};

const VoiceModal: React.FC<VoiceModalProps> = ({
  active,
  voices,
  deactivate,
  selectedVoice,
  setSelectedVoice,
}) => {
  const [showlist, setShowList] = useState(false);
  const [currentVoice, setCurrentVoice] = useState(0);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [currentPlaying, setCurrentPlaying] = useState(0);
  const { playerRef, playerConfig, playerInfo } = usePlayer();

  useEffect(() => {
    const handleListApperance = async () => {
      if (active) {
        setShowList(true);
      } else {
        sleep(500);
        setShowList(false);
      }
    };
    handleListApperance();
  });

  useEffect(() => {
    if (shouldPlay) {
      playerConfig.handleDuration(voices[currentPlaying].duration);
      playerConfig.play();
      setShouldPlay(false);
    }
  }, [currentPlaying]);

  const handleClick = (index: number) => {
    if (index === currentVoice) {
      playerConfig.playPause();
    } else {
      setShouldPlay(true);
      setCurrentVoice(index);
    }
  };

  return (
    <motion.div
      className="fixed flex flex-col items-center justify-center gap-5 w-full h-full left-0 top-0 lg:pl-72 bg-slate-700/20 z-40"
      variants={filterVariants}
      initial="hidden"
      animate={active ? "show" : "hidden"}
      style={active ? { pointerEvents: "auto" } : { pointerEvents: "none" }}
      onClick={deactivate}
    >
      {showlist && (
        <motion.div
          variants={listVariants}
          initial="hidden"
          animate={active ? "show" : "hidden"}
          className="bg-white shadow-lg shadow-violet-400/70 rounded-3xl w-[20rem] sm:w-[25rem] divide-y overflow-hidden"
        >
          {voices.map((voice, index) => {
            return (
              <div
                key={voice.name}
                className="flex px-3 py-4 gap-2 items-center cursor-pointer hover:bg-gray-100"
                onClick={() => setSelectedVoice(voice)}
              >
                <div
                  className="rounded-full w-7 h-7 mr-3"
                  style={{ backgroundImage: voice.gradient }}
                ></div>
                <p className="text-base font-semibold text-gray-700 mr-2">
                  {voice.name}
                </p>
                <p className="leading-6 text-[.7rem] text-gray-500 flex-1">
                  {voice.description}
                </p>
                <div onClick={(e) => e.stopPropagation()}>
                  <StaticCircularProgress
                    pathLength={
                      currentPlaying === index
                        ? playerInfo.progress.playedSeconds /
                          voices[index].duration
                        : 0
                    }
                    size={40}
                    strokeWidth={2}
                  >
                    <div
                      className="w-7 m-1 text-gray-500"
                      onClick={() => handleClick(index)}
                    >
                      {playerInfo.playing && index === currentVoice ? (
                        <PauseIcon />
                      ) : (
                        <PlayIcon />
                      )}
                    </div>
                  </StaticCircularProgress>
                </div>
              </div>
            );
          })}
        </motion.div>
      )}
      {active && (
        <motion.div
          layoutId="pick-voice-btn"
          transition={{ delay: 0 }}
          className="flex items-center w-fit py-2 pl-2 pr-3 gap-2 rounded-2xl bg-gray-200 cursor-pointer"
        >
          <div className="rounded-full w-6 h-6 bg-gradient-to-br from-violet-500 via-fuchsia-400 to-sky-400"></div>
          <p className="text-sm text-gray-800">{selectedVoice?.name}</p>
        </motion.div>
      )}

      {active && (
        <ReactPlayer
          ref={playerRef}
          url={voices[currentVoice].url}
          playing={playerInfo.playing}
          onReady={() => setCurrentPlaying(currentVoice)}
          onProgress={playerConfig.handleProgress}
          onEnded={playerConfig.handleEnded}
          progressInterval={50}
          width={0}
          height={0}
          config={{
            file: {
              attributes: {
                preload: "auto",
              },
              forceAudio: true,
            },
          }}
        />
      )}
    </motion.div>
  );
};

const filterVariants = {
  hidden: { opacity: 0, backdropFilter: "blur(0px)" },
  show: {
    opacity: 1,
    backdropFilter: "blur(4px)",
  },
};

const listVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export default VoiceModal;
