import {
  CheckIcon,
  Cog6ToothIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { FC, RefObject } from "react";
import { useNavigate } from "react-router-dom";
import useContainerWidth from "../hooks/useContainerWidth";

type ProgressBarItemProps = {
  containerWidth: number;
  elementRef?: RefObject<HTMLDivElement> | null;
  active: boolean;
  position: number;
  circleConstant: number;
  Icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  onClick: () => void;
};

type ProgressBarProps = {
  step: number;
};

const icons = [LightBulbIcon, Cog6ToothIcon, CheckIcon];

const ProgressBarItem: FC<ProgressBarItemProps> = ({
  containerWidth,
  elementRef = null,
  active,
  position,
  circleConstant,
  Icon,
  onClick,
}) => {
  const circleDiameter = `${containerWidth * (circleConstant - 0.05)}px`;
  return (
    <div ref={elementRef} className="relative flex-1 z-10">
      <div className="absolute w-full h-full inset-0 flex justify-center items-center">
        <motion.div
          className={`rounded-full bg-violet-300 p-2 cursor-pointer pointer-events-auto`}
          style={{ height: circleDiameter, width: circleDiameter }}
          initial={{ opacity: 0.2, scale: 0.6 }}
          animate={
            position === 1 || position === 3
              ? { opacity: 0.2, scale: 0.6 }
              : position === 2
                ? { opacity: 1, scale: 1 }
                : { opacity: 0.0, scale: 0 }
          }
          transition={{ delay: 0.2, duration: 0.5 }}
          onClick={onClick}
        >
          <Icon />
        </motion.div>
      </div>
      <motion.svg
        height="200"
        width={containerWidth}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className="stroke-[4px] "
      >
        <motion.path
          d={`M0 100 h ${containerWidth * 0.35} c 0 ${containerWidth * 0.2} ${containerWidth * 0.3} ${containerWidth * 0.2} ${containerWidth * 0.3} 0 h ${containerWidth * 0.35}`}
          transition={{ delay: 0.3, duration: 0.5 }}
          animate={active ? { pathLength: 1 } : { pathLength: 0 }}
        />
        <motion.path
          d={`M0 100 h ${containerWidth * 0.35} c 0 ${containerWidth * -0.2} ${containerWidth * 0.3} ${containerWidth * -0.2} ${containerWidth * 0.3} 0 h ${containerWidth * 0.35}`}
          transition={{ delay: 0.3, duration: 0.5 }}
          animate={active ? { pathLength: 1 } : { pathLength: 0 }}
        />
      </motion.svg>
    </div>
  );
};

const ProgressBar: FC<ProgressBarProps> = ({ step }) => {
  const { elementRef, containerWidth } = useContainerWidth();
  const navigate = useNavigate();

  const width = containerWidth / 3;
  const center = step === 0 ? 38 : step === 1 ? 50 : 62;

  const x1 = `${center - 20}%`;
  const x2 = `${center + 20}%`;

  const linex1 = `${center - 15}%`;
  const linex2 = `${center + 5}%`;

  const edge = containerWidth / 2;

  const outlineColor = "#8b5cf6";

  const lineColor = "#c4b5fd";

  const circleConstant = 0.2;

  const circleDiameter = (containerWidth * circleConstant) / 3;

  const lineLength = (containerWidth * ((1 - circleConstant) / 2)) / 3;

  const positiveCurveConstant =
    (containerWidth * (circleConstant * (2 / 3))) / 3;
  const negativeCurveConstant = -positiveCurveConstant;

  return (
    <motion.div
      ref={elementRef}
      className="flex relative"
      animate={
        step === 0
          ? { x: width - edge }
          : step === 1
            ? { x: -edge }
            : { x: -width - edge }
      }
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <div className="flex relative" style={{ left: `${edge}px` }}>
        <ProgressBarItem
          Icon={icons[0]}
          containerWidth={width}
          active={true}
          position={2 - step}
          circleConstant={circleConstant}
          onClick={() => navigate(`?step=${0}`)}
        />
        <ProgressBarItem
          Icon={icons[1]}
          containerWidth={width}
          active={step > 0}
          position={3 - step}
          circleConstant={circleConstant}
          onClick={() => navigate(`?step=${1}`)}
        />
        <ProgressBarItem
          Icon={icons[2]}
          containerWidth={width}
          active={step > 1}
          position={4 - step}
          circleConstant={circleConstant}
          onClick={() => navigate(`?step=${2}`)}
        />
      </div>

      <svg
        height="200"
        width={containerWidth + edge * 2}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className="stroke-[4px] absolute"
      >
        <defs>
          <motion.linearGradient
            id="gradientStroke"
            y1="0%"
            y2="0%"
            initial={{ x1: 0, x2: 0 }}
            animate={{ x1, x2 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <motion.stop
              offset={`${center - 50}%`}
              style={{ stopColor: outlineColor, stopOpacity: 0 }}
            />
            <stop
              offset={`${center}%`}
              style={{ stopColor: outlineColor, stopOpacity: 1 }}
            />
            <stop
              offset={`${center + 50}%`}
              style={{ stopColor: outlineColor, stopOpacity: 0 }}
            />
          </motion.linearGradient>
          <motion.linearGradient
            id="gradientStroke2"
            y1="0%"
            y2="0%"
            initial={{ x1: 0, x2: 0 }}
            animate={{ x1: linex1, x2: linex2 }}
            transition={{ delay: 0.3, duration: 0.3 }}
          >
            <stop
              offset={`0%`}
              style={{ stopColor: lineColor, stopOpacity: 0 }}
            />
            <stop
              offset={`${center - 20}%`}
              style={{ stopColor: lineColor, stopOpacity: 1 }}
            />
            <stop
              offset={`${center + 20}%`}
              style={{ stopColor: lineColor, stopOpacity: 1 }}
            />
            <stop
              offset={`100%`}
              style={{ stopColor: lineColor, stopOpacity: 0 }}
            />
          </motion.linearGradient>
        </defs>
        <motion.path
          d={`M0 100 h ${edge} h ${lineLength} c 0 ${positiveCurveConstant} ${circleDiameter} ${positiveCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${positiveCurveConstant} ${circleDiameter} ${positiveCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${positiveCurveConstant} ${circleDiameter} ${positiveCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${edge}`}
          stroke="url(#gradientStroke)"
        />
        <motion.path
          d={`M0 100 h ${edge} ${lineLength} c 0 ${negativeCurveConstant} ${circleDiameter} ${negativeCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${negativeCurveConstant} ${circleDiameter} ${negativeCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${negativeCurveConstant} ${circleDiameter} ${negativeCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${edge}`}
          stroke="url(#gradientStroke)"
        />
        <motion.path
          d={`M0 100 h ${edge} h ${lineLength} c 0 ${positiveCurveConstant} ${circleDiameter} ${positiveCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${positiveCurveConstant} ${circleDiameter} ${positiveCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${positiveCurveConstant} ${circleDiameter} ${positiveCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${edge}`}
          stroke="url(#gradientStroke2)"
        />
        <motion.path
          d={`M0 100 h ${edge} ${lineLength} c 0 ${negativeCurveConstant} ${circleDiameter} ${negativeCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${negativeCurveConstant} ${circleDiameter} ${negativeCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${lineLength} c 0 ${negativeCurveConstant} ${circleDiameter} ${negativeCurveConstant} ${circleDiameter} 0 h ${lineLength} h ${edge}`}
          stroke="url(#gradientStroke2)"
        />
      </svg>
    </motion.div>
  );
};

export default ProgressBar;
