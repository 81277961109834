import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { UserType } from "../types";

export const NotAuthenticatedRoute: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user !== null && user !== undefined && user.type !== UserType.GUEST) {
      navigate("/projects", { replace: true });
    }
  }, [user, navigate]);

  return user === null || user === undefined || user.type === UserType.GUEST ? (
    <Outlet />
  ) : null;
};
