import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useHandleSearchParam } from "../hooks/useHandleSearchParam";
import { useAuth } from "../provider/authProvider";
import { UserType } from "../types";
import { BorderAnimationButton } from "./Buttons";
import LoadingLogo from "./LoadingLogo";
import { GlassEffectModal } from "./Modals";

type MutationResult<TData> = {
  data: TData | undefined;
  isLoading: boolean;
  error: Error | null;
};

type GenerateStepProps = {
  result: MutationResult<{ message: string }>;
  title: string;
  projectId: string | null;
};

const GenerateStep: React.FC<GenerateStepProps> = ({
  result,
  title,
  projectId,
}) => {
  const { addParam } = useHandleSearchParam("completeAccount");
  const { user } = useAuth();
  const navigate = useNavigate();

  if (result.error !== null) return <span>Something went wrong</span>;
  if (result.isLoading || result.data === undefined)
    return (
      <div className="h-screen grid place-content-center">
        <LoadingLogo />
      </div>
    );

  const handleVoiceoverClick = () => {
    if (user?.type === UserType.GUEST) {
      addParam();
    } else if (user?.type === UserType.DEFAULT) {
      if (projectId) {
        navigate(`/projects/${projectId}`);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-2 sm:px-20 pt-20 lg:pt-4 pb-20">
      <GlassEffectModal className="text-white px-4 sm:px-16 py-8 w-full max-w-2xl">
        <motion.h1
          variants={variants}
          initial="hidden"
          animate="show"
          custom={0}
          className="font-bold text-4xl font-figTree p-2"
        >
          {title}
        </motion.h1>
        <div className="flex flex-col items-center">
          <motion.div
            variants={variants}
            initial="hidden"
            animate="show"
            custom={1}
            className=" py-8 border-b-[3px] border-violet-200/70"
          >
            <p className="text-center font-light max-w-[40rem]">
              {result.data.message}
            </p>
          </motion.div>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="show"
            custom={2}
            className="pt-8 pb-2"
          >
            <BorderAnimationButton
              borderRadius="1rem"
              lineSize={130}
              onPress={handleVoiceoverClick}
            >
              <div className="flex justify-between items-center gap-2 sm:gap-2 text-lg sm:text-xl md:text-2xl font-bold">
                <span>Generate Voiceover</span>
              </div>
            </BorderAnimationButton>
          </motion.div>
        </div>
      </GlassEffectModal>
    </div>
  );
};

export default GenerateStep;

const variants = {
  hidden: (position: number) => ({
    y: 30,
    opacity: 0,
    transition: { duration: 0.3, delay: position * 0.1 + 0.3 },
  }),
  show: (position: number) => ({
    y: 0,
    opacity: 1,
    transition: { duration: 0.3, delay: position * 0.1 + 0.3 },
  }),
};
