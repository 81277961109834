import { useQuery } from "@tanstack/react-query";
import { Project } from "../types/Project";
import { UseQueryReturn } from "../types/UseQueryReturn";
import { callAPI } from "../utils/apiService";

export const useProjects = (): UseQueryReturn<Array<Project>> => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["projects"],
    queryFn: () => callAPI<Array<Project>>("/projects"),
  });

  return { data, error, isLoading };
};
