import { motion } from "framer-motion";
import React from "react";

type ModalProps = {
  children: React.ReactNode;
  className?: string | null;
};

const modalVariants = {
  start: { rotate: -45, scale: 0.5, opacity: 0 },
  end: { rotate: 0, scale: 1, opacity: 1 },
};

const opacityVariants = {
  start: { opacity: 0, scale: 0.95, filter: "blur(4px)" },
  end: {
    opacity: 1,
    scale: 1,
    filter: "blur(0px)",
    transition: { duration: 0.5, ease: "easeOut" },
  },
};

export const GlassEffectModal: React.FC<ModalProps> = ({
  children,
  className = null,
}) => {
  return (
    <motion.div
      variants={opacityVariants}
      initial="start"
      animate="end"
      className={`${className} backdrop-blur-md bg-slate-200/20 border-2 border-slate-200/40 shadow-lg rounded-xl flex flex-col items-center`}
    >
      {children}
    </motion.div>
  );
};

const filterVariants = {
  start: { opacity: 0, backdropFilter: "blur(0px)" },
  end: { opacity: 1, backdropFilter: "blur(4px)" },
};

export const CoverModal: React.FC<ModalProps> = ({
  children,
  className = null,
}) => {
  return (
    <motion.div
      variants={filterVariants}
      initial="start"
      animate="end"
      className="fixed z-50 inset-0 w-screen h-screen bg-slate-950/40 py-20 px-2 sm:px-8 lg:px-28"
    >
      <motion.div
        variants={opacityVariants}
        initial="start"
        animate="end"
        className={`${className} w-full h-full border bg-slate-100 shadow-lg rounded-xl flex flex-col items-center overflow-scroll py-8 px-3 sm:px-6`}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export const SharpModal: React.FC<ModalProps> = ({ children, className }) => {
  return (
    <motion.div
      layout="position"
      variants={opacityVariants}
      initial="start"
      animate="end"
      className={`${className} relative bg-white flex flex-col items-center px-10 py-16 w-3/5`}
      style={{ boxShadow: "0px 0px 50px 0px rgb(0 0 0 / 0.5)" }}
    >
      {children}
    </motion.div>
  );
};
