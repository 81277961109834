import { useQuery } from "@tanstack/react-query";
import { ConfigurationOptions } from "../types/Configuration";
import { UseQueryReturn } from "../types/UseQueryReturn";
import { callAPI } from "../utils/apiService";

export const useConfiguration = (): UseQueryReturn<ConfigurationOptions> => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["configurations"],
    queryFn: () => callAPI<ConfigurationOptions>("/configurations"),
  });

  return { data, error, isLoading };
};
