import { Project } from "./types/Project";

// User
export enum UserType {
  GUEST = "GUEST",
  DEFAULT = "DEFAULT",
  GOOGLE = "GOOGLE",
}

export type User = {
  _id: string;
  type: UserType;
  email: string;
  famililarity: string | null;
  purpose: string | null;
  work: string | null;
  name: string;
  tokens: number | null;
  tokensRefresh: number | null;
};

//Sorting
export type UseSortItemsProps = {
  data: Array<Project> | undefined;
};

export enum SortBy {
  Name = "Name",
  Date = "Date",
}

export enum SortingOrder {
  Ascending,
  Descending,
}

export type SortingConfig = {
  sortBy: SortBy;
  sortingOrder: SortingOrder;
  searchValue: string;
  sortByOptions: Array<SortBy>;
};

export type SetSortingConfig = {
  sortBy: (value: SortBy) => void;
  sortingOrder: () => void;
  searchValue: (value: string) => void;
};

export type UseSortItemsReturn = {
  sortingConfig: SortingConfig;
  setSortingConfig: SetSortingConfig;
  items: Array<Project>;
};

export type GenerateVoiceOverParams = {
  id: string;
  voice: string;
};
