import { useCallback, useEffect, useMemo, useState } from "react";
import { Project } from "../types/Project";

import {
  SortBy,
  UseSortItemsProps,
  UseSortItemsReturn,
  SortingOrder,
  SortingConfig,
  SetSortingConfig,
} from "../types";

const sortByOptions = [SortBy.Name, SortBy.Date];

const useSortItems = ({ data }: UseSortItemsProps): UseSortItemsReturn => {
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Date);
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>(
    SortingOrder.Descending,
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [items, setItems] = useState<Array<Project>>([]);

  useEffect(() => {
    if (!data) return;
    let sortedItems = sortItems(data, sortBy, searchValue);

    if (sortingOrder === SortingOrder.Descending) {
      sortedItems = sortedItems.reverse();
    }

    setItems(sortedItems);
  }, [data, sortBy, sortingOrder, searchValue]);

  const sortingConfig: SortingConfig = {
    sortBy,
    sortingOrder,
    searchValue,
    sortByOptions,
  };

  const setSortingConfig: SetSortingConfig = {
    sortBy: useCallback((value) => setSortBy(value), []),
    searchValue: useCallback((value) => setSearchValue(value), []),
    sortingOrder: useCallback(
      () =>
        setSortingOrder((prev) =>
          prev === SortingOrder.Ascending
            ? SortingOrder.Descending
            : SortingOrder.Ascending,
        ),
      [],
    ),
  };

  const useSortItemsReturn: UseSortItemsReturn = useMemo(
    () => ({
      sortingConfig,
      setSortingConfig,
      items,
    }),
    [items, sortingConfig, setSortingConfig],
  );

  return useSortItemsReturn;
};

const sortItems = (
  items: Array<Project>,
  sortBy: SortBy,
  searchValue: string,
) => {
  const searchedProjects =
    searchValue.length > 0
      ? items.filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : items;

  return sortProjects(searchedProjects, sortBy) as Project[];
};

const sortProjects = (projects: Project[], sortBy: SortBy) => {
  switch (sortBy) {
    case "Name":
      return [...projects].sort((a, b) => a.name.localeCompare(b.name));
    case "Date":
      return [...projects].sort((a, b) => a.timestamp - b.timestamp);
  }
  return [];
};

export default useSortItems;
