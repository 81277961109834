import { motion } from "framer-motion";

const LoadingLogo = ({ width = "w-32" }: { width?: string }) => {
  return (
    <div className={`${width}`}>
      <motion.svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 362.79 326.89"
      >
        <defs>
          <linearGradient
            id="linear-gradient-2"
            x1="0%"
            y1="50%"
            x2="100%"
            y2="50%"
          >
            <stop offset="0%" stopColor="#fcfcff" />
            <stop offset="2%" stopColor="#b1b2fa" />
            <stop offset="12%" stopColor="#f4f5fe" />
            <stop offset="19%" stopColor="#bdbefb" />
            <stop offset="51%" stopColor="#ffffff" />
            <stop offset="100%" stopColor="#b1b2fa" />
          </linearGradient>
        </defs>
        {/* Fallback path with a solid stroke */}
        <motion.path
          className="fallback-path"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: [0, 1, 0] }}
          transition={{ duration: 2.5, ease: "easeInOut", repeat: Infinity }}
          style={{
            fill: "transparent",
            stroke: "#b1b2fa", // Fallback color
            strokeMiterlimit: 10,
            strokeWidth: "10px",
          }}
          d="M.4,5.42s16.16,41.77,36.74,94.13c32.99,83.94,77.33,195.13,84.66,205.21,7.94,10.91,23.81,23.81,49.09,21.79,24.92-1.99,46.29-18.59,55.81-41.7,30.98-75.18,101.01-252.29,111.99-280.31.75-1.93-2.2-3.54-3.57-3.57-32.28-.72-86.46-1.51-86.54.05-11.07,28.19-63.63,166.81-71.05,186.41-.83,2.2-2.34,4.13-4.41,5.24-2.69,1.44-6.6,1.9-10.53-3.36C152.75,176.16,94.05,15.55,91.12,1.29,1.84,1.83-1.25-1.05.4,5.42Z"
        />
        <motion.path
          className="cls-1"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: [0, 1, 0] }}
          transition={{ duration: 2.5, ease: "easeInOut", repeat: Infinity }}
          style={{
            fill: "none",
            stroke: "url(#linear-gradient-2)",
            strokeMiterlimit: 10,
            strokeWidth: "10px",
          }}
          d="M.4,5.42s16.16,41.77,36.74,94.13c32.99,83.94,77.33,195.13,84.66,205.21,7.94,10.91,23.81,23.81,49.09,21.79,24.92-1.99,46.29-18.59,55.81-41.7,30.98-75.18,101.01-252.29,111.99-280.31.75-1.93-2.2-3.54-3.57-3.57-32.28-.72-86.46-1.51-86.54.05-11.07,28.19-63.63,166.81-71.05,186.41-.83,2.2-2.34,4.13-4.41,5.24-2.69,1.44-6.6,1.9-10.53-3.36C152.75,176.16,94.05,15.55,91.12,1.29,1.84,1.83-1.25-1.05.4,5.42Z"
        />
      </motion.svg>
    </div>
  );
};

export default LoadingLogo;
