import { useState, useEffect, useMemo } from "react";

type ViewportHeightReturn = {
  viewportHeight: number;
  isSmallHeight: boolean | null;
};

type ViewportHeightProps = {
  maxHeight?: number;
  phoneOnly?: boolean;
};

const useViewportHeight = ({
  maxHeight,
  phoneOnly = false,
}: ViewportHeightProps): ViewportHeightReturn => {
  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight,
  );
  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth);

  const isSmallHeight: boolean | null = useMemo(() => {
    const isPhone = viewportWidth < 500;
    if (maxHeight) {
      return phoneOnly
        ? viewportHeight <= maxHeight && isPhone
        : viewportHeight <= maxHeight;
    } else return null;
  }, [maxHeight, viewportHeight, viewportWidth]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    viewportHeight,
    isSmallHeight,
  };
};

export default useViewportHeight;
