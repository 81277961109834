import { MotionValue, useTransform } from "framer-motion";
import { useIsLandscape } from "./useIsLandscape";

type UseExplanationAnimationProps = {
  scrollYProgress: MotionValue<number>;
  index: number;
};

const useExplanationAnimation = ({
  scrollYProgress,
  index,
}: UseExplanationAnimationProps) => {
  const isLandscape = useIsLandscape();
  const n = index * 0.3;
  const xPos = index === 0 ? 110 : index === 1 ? 0 : -110;
  const yPos = isLandscape ? -180 : -150;

  const x = useTransform(
    scrollYProgress,
    [n + 0, n + 0.1, n + 0.2, n + 0.3],
    [0, xPos, xPos, 0],
  );
  const y = useTransform(
    scrollYProgress,
    [n + 0, n + 0.1, n + 0.2, n + 0.3],
    [0, yPos, yPos, 0],
  );
  const z = useTransform(
    scrollYProgress,
    [n + 0, n + 0.001, n + 0.299, n + 0.3],
    [1, 50, 50, 1],
  );

  const opacity = useTransform(
    scrollYProgress,
    [n + 0, n + 0.1, n + 0.2, n + 0.3],
    [0, 1, 1, 0],
  );

  const shadow = useTransform(
    scrollYProgress,
    [n + 0, n + 0.1, n + 0.2, n + 0.3],
    [
      "0 20px 25px -5px rgba(196, 181, 253, .0)",
      "0 20px 25px -5px rgba(196, 181, 253, .7)",
      "0 20px 25px -5px rgba(196, 181, 253, .7)",
      "0 20px 25px -5px rgba(196, 181, 253, .0)",
    ],
  );

  const bg = useTransform(
    scrollYProgress,
    [n + 0, n + 0.1, n + 0.2, n + 0.3],
    [
      "rgba(255, 255, 255, .0)",
      "rgba(255, 255, 255, 1)",
      "rgba(255, 255, 255, 1)",
      "rgba(255, 255, 255, .0)",
    ],
  );
  return { x, y, z, opacity, bg, shadow };
};

export default useExplanationAnimation;
