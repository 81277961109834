import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import Form from "../components/Form";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { useConfiguration } from "../hooks/useConfigurations";
import { ConfigurationOptions } from "../types/Configuration";
import { callAPI } from "../utils/apiService";

import { useNavigate, useSearchParams } from "react-router-dom";
import ConfigurationStep from "../components/ConfigurationStep";
import GenerateStep from "../components/GenerateStep";
import { GradientBackground2 } from "../components/GradientBackgrounds";
import ProgressBar from "../components/ProgressBar";
import TopicStep from "../components/TopicStep";
import Warnings from "../components/Warnings";
import { useIsLandscape } from "../hooks/useIsLandscape";
import {
  DefaultWarning,
  SuccessNotice,
  useWarnings,
  Warning,
} from "../hooks/useWarnings";
import { useAuth } from "../provider/authProvider";
import { UserType } from "../types";
import { Project, ProjectCreateParams } from "../types/Project";

const schema = yup.object().shape({
  topic: yup.string().required(),
  retention: yup.string().required(),
  hook: yup.string().required(),
  ending: yup.string().required(),
});

export type FormFields = yup.InferType<typeof schema>;

type StepProps = {
  step: number;
  configOptions: ConfigurationOptions;
  form: UseFormReturn<FormFields>;
  nextPage: () => void;
  response: MutationResult<{ message: string }>;
  projectId: string | null;
  pushWarning: (item: Warning) => void;
};

type MutationResult<TData> = {
  data: TData | undefined;
  isLoading: boolean;
  error: Error | null;
};

const Steps: React.FC<StepProps> = ({
  step,
  configOptions,
  form,
  nextPage,
  response,
  projectId,
  pushWarning,
}) => {
  const isLandscape = useIsLandscape();
  return (
    <div className="relative w-full h-full flex flex-col items-center">
      <div className="left-0 lg:left-72 top-0 fixed w-full h-full bg-secondary -z-50"></div>
      <div className="fixed left-0 lg:left-72 top-0 w-full h-full -z-50">
        <GradientBackground2 />
      </div>
      {step === 0 ? (
        <TopicStep
          key={"topic"}
          form={form}
          nextPage={nextPage}
          pushWarning={pushWarning}
        />
      ) : step === 1 ? (
        <ConfigurationStep
          form={form}
          config={configOptions}
          nextPage={nextPage}
          pushWarning={pushWarning}
        />
      ) : step === 2 ? (
        <GenerateStep
          result={response}
          title={form.getValues("topic")}
          projectId={projectId}
        />
      ) : (
        <span>Something went wrong.</span>
      )}
      {!isLandscape && (
        <div className="fixed -bottom-5 sm:bottom-5 w-[150%] sm:w-full lg:w-2/3 h-48 pointer-events-none">
          <ProgressBar step={step} />
        </div>
      )}
    </div>
  );
};

export default function NewProject() {
  const { warnings, pushWarning, removeWarning, clearWarnings } = useWarnings();
  const { data, error, isLoading } = useConfiguration();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const stepSearchParam = parseInt(searchParams.get("step") as string);
  const defaultTab = isNaN(stepSearchParam) ? 0 : stepSearchParam;
  const [step, setStep] = useState<number>(defaultTab || 0);
  const { user } = useAuth();
  const [projectId, setProjectId] = useState<string | null>(null);

  const nextPage = () => {
    navigate(`?step=${step + 1}`);
  };

  useEffect(() => {
    if ([0, 1, 2].includes(stepSearchParam)) {
      setStep(stepSearchParam);
    } else {
      setStep(0);
    }
  }, [stepSearchParam]);

  const form = useForm<FormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      topic: "",
      retention: "",
      hook: "",
      ending: "",
    },
  });

  const storeProjectMutation = useMutation({
    mutationFn: ({ script, topic }: ProjectCreateParams) =>
      callAPI<Project>("/projects/create", {
        script,
        topic,
      }),
    onSuccess: (response) => {
      pushWarning(
        new SuccessNotice("Your script was successfully stored to your ", {
          style: "styled",
          link: {
            to: "/projects",
            title: "projects",
          },
        }),
      );
      setProjectId(response.id);
    },
    onError: (err) => console.log("Error saving project: " + err),
  });

  const generateMutation = useMutation({
    mutationFn: ({ topic, retention, hook, ending }: FormFields) =>
      callAPI<{ message: string }>("/generate", {
        topic,
        retention,
        hook,
        ending,
      }),
    onSuccess: (response) => {
      handleGenerationComplete(response.message);
    },
    onError: (err: Error) => {},
  });

  const mutationResult = {
    data: generateMutation.data,
    isLoading: generateMutation.isPending,
    error: generateMutation.error,
  };

  const handleStore = (script: string) => {
    if (script) {
      storeProjectMutation.mutate({
        script: script,
        topic: form.getValues("topic"),
      });
    }
  };

  const handleGenerate = async (params: FormFields) => {
    generateMutation.mutate(params);
  };

  const handleGenerationComplete = (script: string) => {
    switch (user?.type) {
      case UserType.DEFAULT:
        handleStore(script);
        break;
      case UserType.GUEST:
        pushWarning(
          new DefaultWarning("To save script and generate voiceover you must", {
            style: "styled",
            link: {
              to: "?step=2&completeAccount=true",
              title: "complete your account",
            },
          }),
        );
        break;
    }
  };

  if (error !== null) return <span>Something went wrong</span>;
  if (isLoading || data === undefined) return <Loading />;

  return (
    <Layout transparent>
      <Form onSubmit={form.handleSubmit(handleGenerate)}>
        <Steps
          step={step}
          configOptions={data}
          form={form}
          nextPage={nextPage}
          response={mutationResult}
          projectId={projectId}
          pushWarning={pushWarning}
        />
      </Form>
      <Warnings
        list={warnings}
        onClose={(item) => removeWarning(item)}
        onDurationEnd={(item) => removeWarning(item)}
      />
    </Layout>
  );
}
