import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, Fragment } from "react";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { ConfigurationItem } from "../types/Configuration";
import { Menu, Transition } from "@headlessui/react";

type SelectProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  form: UseFormReturn<TFieldValues>;
  options: Array<ConfigurationItem>;
  selectionCallback: () => void;
  category: string;
};

type FormSelectProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  form: UseFormReturn<TFieldValues>;
  options: Array<string>;
};

const menuVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: -10 },
};

const Select = <T extends FieldValues>({
  name,
  form,
  options,
  category,
  selectionCallback,
}: SelectProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { onChange, value } }) => {
        useEffect(() => {
          if (value) {
            setSelected(value);
            setIsOpen(false);
          }
        }, [value]);

        return (
          <div className="relative text-gray-700">
            <div
              className="flex justify-between items-center p-3 bg-white rounded-xl cursor-pointer text-sm sm:text-base"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              {selected ? value : "Select"}
              <span className="ml-2 w-8 h-8 ">
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </span>
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.ul
                  className="absolute left-0 sm:-left-5 lg:left-3 right-0 lg:w-4/5 mt-2 z-10"
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={menuVariants}
                >
                  <motion.li
                    className="p-3 hover:bg-gray-100 text-white hover:text-black cursor-pointer border border-gray-300/50 rounded-xl my-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-fuchsia-500 hover:from-indigo-300 hover:via-purple-300 hover:to-fuchsia-300 backdrop-blur-md shadow-md"
                    onClick={() => {
                      onChange("Let Our AI Decide"), selectionCallback();
                    }}
                    variants={itemVariants}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <h3 className="text-sm sm:text-base">
                          Let Our AI Decide
                        </h3>
                      </div>
                      {"Let Our AI Decide" === value && (
                        <div className="w-5 h-5">
                          <CheckIcon />
                        </div>
                      )}
                    </div>
                  </motion.li>
                  {options.map((option) => (
                    <motion.li
                      key={option.value}
                      className="p-3 hover:bg-gray-100 text-white hover:text-black cursor-pointer border border-gray-300/50 rounded-xl my-2 backdrop-blur-md shadow-md"
                      onClick={() => {
                        onChange(option.value), selectionCallback();
                      }}
                      variants={itemVariants}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col">
                          <h3 className="text-sm sm:text-base">
                            {option.value}
                          </h3>
                          {!option.description ? null : (
                            <span className="text-xs text-gray-400">
                              {option.description}
                            </span>
                          )}
                        </div>
                        {option.value === value && (
                          <div className="w-5 h-5">
                            <CheckIcon />
                          </div>
                        )}
                      </div>
                    </motion.li>
                  ))}
                </motion.ul>
              )}
            </AnimatePresence>
          </div>
        );
      }}
    />
  );
};

export const FormSelect = <T extends FieldValues>({
  name,
  form,
  options,
}: FormSelectProps<T>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { onChange, value } }) => (
        <Menu as="div" className="group relative inline-flex w-full">
          <div className="w-full max-w-96">
            <Menu.Button className="inline-flex items-center justify-between w-full px-3 py-2 rounded-md shadow-text-field bg-white hover:bg-gray-50 group-focus-within:shadow-focus transition-[box-shadow]">
              <span className="flex items-center">
                {(() => {
                  const selected = options.find((item) => item === value);

                  return (
                    <span className="text-base font-medium text-gray-700">
                      {selected ? selected : "Select"}
                    </span>
                  );
                })()}
              </span>

              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2 w-72 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {options.map((item) => (
                  <Menu.Item key={item}>
                    <button
                      type="button"
                      className={`block w-full px-4 py-2 hover:bg-gray-100`}
                      onClick={() => onChange(item)}
                    >
                      <div className="flex justify-between">
                        <div className="flex flex-col text-left">
                          <span className="text-sm text-gray-700">{item}</span>
                        </div>

                        {value === item && (
                          <div className="flex items-center">
                            <CheckIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    />
  );
};

export default Select;
