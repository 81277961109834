import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
  FolderIcon,
  PlusIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useHandleSearchParam } from "../hooks/useHandleSearchParam";
import { useAuth } from "../provider/authProvider";
import { UserType } from "../types";
import { formatUnixDate } from "../utils/formatUnixDate";
import { StaticCircularProgress } from "./CircularProgress";
import CompleteAccount from "./CompleteAccount";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "./dropdown";
import LatestProjects from "./LatestProjects";

const dropdownVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const itemVariants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: -10 },
};

const navigation = [
  { name: "New script", href: "/new", icon: PlusIcon },
  { name: "Projects", href: "/projects", icon: FolderIcon },
];

const faq = [
  { text: "When is the AI Video Generator Launching?", href: "/#launch" },
  { text: "What is the HRC framework?", href: "/#explanation" },
];

type LayoutProps = {
  children: ReactNode;
  transparent?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, transparent = false }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [questionsOpen, setQuestionsOpen] = useState(false);
  const navigate = useNavigate();
  const { hasParam, addParam } = useHandleSearchParam("completeAccount");
  const { setToken, user } = useAuth();

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div
                className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-2 ring-1 ring-white/10"
                style={{ backgroundColor: "#181a33" }}
              >
                <Link to={"/"} className="flex h-8 w-8 items-center mt-4">
                  <img src="/shadow-logo.png" alt="logo" />
                </Link>

                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item.href}
                              className={({ isActive }) =>
                                classNames(
                                  { "bg-gray-800 text-white": isActive },
                                  {
                                    "text-gray-400 hover:bg-gray-800 hover:text-white":
                                      !isActive,
                                  },
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                )
                              }
                            >
                              <item.icon
                                aria-hidden="true"
                                className="h-6 w-6 shrink-0"
                              />
                              {item.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {user && <LatestProjects />}
                    <motion.li className="relative">
                      <motion.div
                        layout
                        onClick={() => setQuestionsOpen((prev) => !prev)}
                        className="cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                      >
                        {!questionsOpen ? (
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0"
                          />
                        ) : (
                          <ChevronUpIcon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0"
                          />
                        )}
                        FaQ
                      </motion.div>
                      {questionsOpen && (
                        <motion.ul
                          layout
                          variants={dropdownVariants}
                          initial="closed"
                          animate="open"
                          className="ml-9"
                        >
                          {faq.map((item, index) => (
                            <motion.li
                              layout
                              key={index}
                              variants={itemVariants}
                            >
                              <Link
                                to={item.href}
                                className="text-gray-400/80 text-sm font-semibold leading-6 mb-2 cursor-pointer hover:text-gray-200"
                              >
                                {item.text}
                              </Link>
                            </motion.li>
                          ))}
                        </motion.ul>
                      )}
                    </motion.li>
                    {user?.tokens && (
                      <li className="-mx-2 mt-auto bg-gray-800/50 hover:bg-gray-800 rounded-lg flex flex-col p-3  text-gray-400">
                        <h2 className="text-gray-200 font-bold pb-1 border-b border-gray-600">
                          Voiceover Tokens
                        </h2>
                        <div className="flex mt-1">
                          <div className="flex-1">
                            <p className="flex justify-between text-sm">
                              <span className="font-semibold text-gray-300">
                                Total:{" "}
                              </span>
                              <span>1500</span>
                            </p>
                            <p className="flex justify-between text-sm">
                              <span className="font-semibold text-gray-300">
                                Remaining:{" "}
                              </span>
                              <span>{user.tokens}</span>
                            </p>
                            {user.tokensRefresh && (
                              <p className="flex justify-between text-sm">
                                <span className="font-semibold text-gray-300">
                                  Refreshes:{" "}
                                </span>
                                <span>
                                  {formatUnixDate(user.tokensRefresh)}
                                </span>
                              </p>
                            )}
                          </div>
                          <div className="flex items-center pl-4 pr-2">
                            <StaticCircularProgress
                              size={50}
                              strokeWidth={2}
                              pathLength={Math.abs(user.tokens / 1500 - 1)}
                              backgroundColor="#374151"
                            >
                              <div className="text-[.6rem] font-bold text-gray-200">
                                {Math.floor(
                                  Math.abs((user.tokens / 1500) * 100 - 100),
                                )}
                                %
                              </div>
                            </StaticCircularProgress>
                          </div>
                        </div>
                      </li>
                    )}
                    <li
                      style={
                        user?.tokens ? { marginTop: 0 } : { marginTop: "auto" }
                      }
                      className="-mx-6 mt-auto text-gray-200 px-4 py-3 hover:bg-gray-800"
                    >
                      <Dropdown>
                        <DropdownButton
                          as={"div"}
                          className="flex items-center gap-3 cursor-pointer"
                        >
                          <div className="w-8">
                            <UserIcon />
                          </div>

                          <div className="text-sm">
                            <h2 className="text-base font-semibold text-ellipsis">
                              {user?.type === UserType.DEFAULT
                                ? user.name
                                : "Guest"}
                            </h2>
                            <p className="text-gray-400 text-ellipsis">
                              {user?.email}
                            </p>
                          </div>
                        </DropdownButton>
                        <DropdownMenu
                          className="min-w-64 z-50"
                          anchor="bottom end"
                        >
                          {user?.type === UserType.GUEST && (
                            <>
                              <DropdownItem onClick={addParam}>
                                <UserIcon />
                                <DropdownLabel>Complete Account</DropdownLabel>
                              </DropdownItem>
                              <DropdownDivider />
                            </>
                          )}
                          {/* <DropdownItem href="/settings">
                            <Cog8ToothIcon />
                            <DropdownLabel>Settings</DropdownLabel>
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={() => setToken(null)}
                            className="hover:bg-violet-500"
                          >
                            <ArrowRightStartOnRectangleIcon />
                            <DropdownLabel>Sign out</DropdownLabel>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div
            className="flex grow flex-col gap-y-5 overflow-y-auto px-6 border-r border-violet-300/10"
            style={{ backgroundColor: "#181a33" }}
          >
            <Link to={"/"} className="flex h-8 w-8 items-center mt-4">
              <img src="/shadow-logo.png" alt="logo" />
            </Link>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              { "bg-gray-800 text-white": isActive },
                              {
                                "text-gray-400 hover:bg-gray-800 hover:text-white":
                                  !isActive,
                              },
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            )
                          }
                        >
                          <item.icon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                {user && <LatestProjects />}
                <motion.li className="relative">
                  <motion.div
                    layout
                    onClick={() => setQuestionsOpen((prev) => !prev)}
                    className="cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    {!questionsOpen ? (
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                      />
                    ) : (
                      <ChevronUpIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                      />
                    )}
                    FaQ
                  </motion.div>
                  {questionsOpen && (
                    <motion.ul
                      layout
                      variants={dropdownVariants}
                      initial="closed"
                      animate="open"
                      className="ml-9"
                    >
                      {faq.map((item, index) => (
                        <motion.li
                          layout
                          key={index}
                          variants={itemVariants}
                          onClick={() => navigate(item.href)}
                          className="text-gray-400/80 text-sm mb-2 font-semibold leading-6 cursor-pointer hover:text-gray-200"
                        >
                          {item.text}
                        </motion.li>
                      ))}
                    </motion.ul>
                  )}
                </motion.li>
                {user?.tokens && (
                  <li className="-mx-2 mt-auto bg-gray-800/50 hover:bg-gray-800 rounded-lg flex flex-col p-3  text-gray-400">
                    <h2 className="text-gray-200 font-bold pb-1 border-b border-gray-600">
                      Voiceover Tokens
                    </h2>
                    <div className="flex mt-1">
                      <div className="flex-1">
                        <p className="flex justify-between text-sm">
                          <span className="font-semibold text-gray-300">
                            Total:{" "}
                          </span>
                          <span>1500</span>
                        </p>
                        <p className="flex justify-between text-sm">
                          <span className="font-semibold text-gray-300">
                            Remaining:{" "}
                          </span>
                          <span>{user.tokens}</span>
                        </p>
                        {user.tokensRefresh && (
                          <p className="flex justify-between text-sm">
                            <span className="font-semibold text-gray-300">
                              Refreshes:{" "}
                            </span>
                            <span>{formatUnixDate(user.tokensRefresh)}</span>
                          </p>
                        )}
                      </div>
                      <div className="flex items-center pl-4 pr-2">
                        <StaticCircularProgress
                          size={50}
                          strokeWidth={2}
                          pathLength={Math.abs(user.tokens / 1500 - 1)}
                          backgroundColor="#374151"
                        >
                          <div className="text-[.6rem] font-bold text-gray-200">
                            {Math.floor(
                              Math.abs((user.tokens / 1500) * 100 - 100),
                            )}
                            %
                          </div>
                        </StaticCircularProgress>
                      </div>
                    </div>
                  </li>
                )}
                <li
                  style={
                    user?.tokens ? { marginTop: 0 } : { marginTop: "auto" }
                  }
                  className="-mx-6 mt-auto text-gray-200 px-4 py-3 hover:bg-gray-800"
                >
                  <Dropdown>
                    <DropdownButton
                      as={"div"}
                      className="flex items-center gap-3 cursor-pointer"
                    >
                      <div className="w-8">
                        <UserIcon />
                      </div>

                      <div className="text-sm">
                        <h2 className="text-base font-semibold text-ellipsis">
                          {user?.type === UserType.DEFAULT
                            ? user.name
                            : "Guest"}
                        </h2>
                        <p className="text-gray-400 text-ellipsis">
                          {user?.email}
                        </p>
                      </div>
                    </DropdownButton>
                    <DropdownMenu className="min-w-64 z-50" anchor="bottom end">
                      {user?.type === UserType.GUEST && (
                        <>
                          <DropdownItem onClick={addParam}>
                            <UserIcon />
                            <DropdownLabel>Complete Account</DropdownLabel>
                          </DropdownItem>
                          <DropdownDivider />
                        </>
                      )}
                      {/* <DropdownItem href="/settings">
                        <Cog8ToothIcon />
                        <DropdownLabel>Settings</DropdownLabel>
                      </DropdownItem> */}

                      <DropdownItem
                        onClick={() => setToken(null)}
                        className="hover:bg-violet-500"
                      >
                        <ArrowRightStartOnRectangleIcon />
                        <DropdownLabel>Sign out</DropdownLabel>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div
          className="sticky top-0 z-40 flex items-center gap-x-6 px-4 py-4 shadow-sm sm:px-6 lg:hidden pointer-events-none"
          style={
            transparent
              ? { backgroundColor: "transparent" }
              : { backgroundColor: "#181a33" }
          }
        >
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className={classNames(
              "-m-2.5 p-2.5 lg:hidden pointer-events-auto",
              { "text-gray-200": transparent },
              { "text-gray-400": !transparent },
            )}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon
              aria-hidden="true"
              className={classNames(
                { "h-8 w-8 stroke-2": transparent },
                { "h-6 w-6": !transparent },
              )}
            />
          </button>
          {/* <div className="flex-1 text-sm font-semibold leading-6 text-white">
            Dashboard
          </div> */}
          {/* <a href="#">
            <span className="sr-only">Your profile</span>
            <div className="text-white w-8">
              <UserCircleIcon />
            </div>
          </a> */}
        </div>

        <main className="max-lg:-mt-16 lg:pl-72 w-screen h-screen box-border relative">
          {hasParam && <CompleteAccount />}
          <div className="overflow-x-hidden w-full h-full overscroll-contain">
            {children}
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
