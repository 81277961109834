import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import DownloadButton from "../components/DownloadButton";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { SharpModal } from "../components/Modals";
import VoiceModal from "../components/VoiceModal";
import { VoiceoverPlayerDisplay } from "../components/VoiceoverPlayerDisplay";
import Warnings from "../components/Warnings";
import { useHandleSearchParam } from "../hooks/useHandleSearchParam";
import usePlayer from "../hooks/usePlayer";
import {
  DefaultWarning,
  SuccessNotice,
  useWarnings,
} from "../hooks/useWarnings";
import { GenerateVoiceOverParams } from "../types";
import {
  ProjectReturn,
  Project as ProjectType,
  ProjectUpdateParams,
} from "../types/Project";
import { callAPI } from "../utils/apiService";
import { copyToClipboard } from "../utils/functions";
import { useAuth } from "../provider/authProvider";

function countWords(str: string): number {
  // Trim the string to remove leading and trailing spaces and then split by spaces or punctuation marks
  const words = str.trim().split(/\s+|\b[.,!?:;\-\(\)\[\]\{\}]\b/);

  // Filter out any empty strings that may result from the split
  const filteredWords = words.filter((word) => word.length > 0);

  // Return the length of the filtered array, which represents the word count
  return filteredWords.length;
}

export type VoiceoverStatus = "idle" | "loading" | "success";

const Project = () => {
  const { id } = useParams<{ id: string }>();
  const { signNewToken, user } = useAuth();
  const navigate = useNavigate();
  const { hasParam, addParam, removeParam } = useHandleSearchParam(
    "state",
    "voiceover",
  );
  const { warnings, pushWarning, removeWarning, clearWarnings } = useWarnings();
  const { data, error, isLoading } = useQuery({
    queryKey: ["project", id],
    queryFn: () => callAPI<ProjectReturn>(`/projects/${id}`),
  });
  const { playerRef, playerConfig, playerInfo } = usePlayer();

  const queryClient = useQueryClient();

  const [wordCount, setWordCount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(data?.voices[0]);
  const [isPickingVoice, setIsPickingVoice] = useState(false);

  const [voiceoverStatus, setVoiceoverStatus] = useState<VoiceoverStatus>(
    hasParam ? "success" : "idle",
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const wordMax = 60;

  const charMax = 285;

  useEffect(() => {
    setWordCount(countWords(inputValue));
    handleInput();
    if (data) {
      if (data.project.script !== inputValue) {
        setAllowUpdate(true);
      } else {
        setAllowUpdate(false);
      }
    }
  }, [inputValue]);

  useEffect(() => {
    if (data) {
      setInputValue(data.project.script);
      if (data.project.voice_over) {
        playerConfig.handleDuration(data.project.voice_over.duration);
        addParam();
      }
      if (!selectedVoice) {
        setSelectedVoice(data.voices[0]);
      }
    }
    handleInput();
  }, [data]);

  useEffect(() => {
    if (hasParam) {
      setVoiceoverStatus("success");
    } else {
      setVoiceoverStatus("idle");
    }
  }, [hasParam]);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  //Generate voiceover function
  const handleGenerateVoiceover = async () => {
    if (inputValue.length > charMax) {
      pushWarning(
        new DefaultWarning("Character limit for voiceover overpassed"),
      );
      return;
    }
    if (!user?.tokens || inputValue.length > user?.tokens) {
      pushWarning(new DefaultWarning("Out of free monthly tokens"));
      return;
    }
    if (data) {
      setVoiceoverStatus("loading");
      await handleUpdateBeforeGenerate({ script: inputValue });
      voiceoverMutation.mutate({
        id: data.project.id,
        voice: selectedVoice?.id || "66d5cd8df7adaab9ef07cece", //Selected should always exist, else default voice id
      });
    }
  };

  const voiceoverMutation = useMutation({
    mutationFn: ({ id, voice }: GenerateVoiceOverParams) =>
      callAPI(`/generate/voice-over`, {
        id,
        voice,
      }),
    onSuccess: () => {
      console.log("Successfully generating voiceover");
      queryClient.invalidateQueries({ queryKey: ["project", id] });
      signNewToken();
    },
    onError: (err) => console.log("Error generating voiceover: " + err),
  });

  // Updating functions
  const updateMutation = useMutation({
    mutationFn: ({ script }: ProjectUpdateParams) =>
      callAPI<ProjectType>(`/projects/${id}/update`, {
        script,
      }),
    onSuccess: (response) => {
      console.log("Successfully updated project: " + response.script);
      setAllowUpdate(false);
    },
    onError: (err) => console.log("Error updating project: " + err),
  });

  const handleUpdateProject = ({ script, topic }: ProjectUpdateParams) => {
    if ((script || topic) && allowUpdate) {
      updateMutation.mutate({ script, topic });
    }
  };

  const handleUpdateBeforeGenerate = ({
    script,
    topic,
  }: ProjectUpdateParams): Promise<void> => {
    return new Promise((resolve, reject) => {
      if ((script || topic) && allowUpdate) {
        updateMutation.mutate(
          { script, topic },
          {
            onSuccess: () => {
              resolve(), setAllowUpdate(false);
            },
            onError: (error) => reject(error),
          },
        );
      } else {
        resolve();
      }
    });
  };

  if (error !== null) return <span>Something went wrong</span>;
  if (isLoading || data === undefined) return <Loading />;

  const project = data.project;

  return (
    <Layout>
      <VoiceModal
        active={isPickingVoice}
        voices={data.voices}
        selectedVoice={selectedVoice}
        setSelectedVoice={setSelectedVoice}
        deactivate={() => setIsPickingVoice(false)}
      />
      <div className="min-h-full w-full py-20 flex flex-col items-center justify-center px-4 sm:px-20 md:px-36">
        <SharpModal className="gap-6 w-full mx-8 px-4 sm:px-12 md:px-16 max-w-3xl">
          <div className="absolute w-full top-0 flex justify-between px-2 sm:px-4 py-4 sm:py-6 text-gray-500">
            <div
              onClick={() => {
                handleUpdateProject({ script: inputValue }),
                  navigate("/projects");
              }}
              className="w-10 sm:w-12 cursor-pointer"
            >
              <ChevronLeftIcon strokeWidth={2.5} />
            </div>
          </div>
          <h1 className="font-figTree font-bold text-4xl text-center mt-3 sm:mt-2">
            {project.name}
          </h1>
          <textarea
            ref={textareaRef}
            rows={4}
            className="w-full text-lg text-center outline-none"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => handleUpdateProject({ script: inputValue })}
          ></textarea>

          {/* Copy button */}
          <div className="flex items-center gap-3">
            <button
              onClick={() => {
                copyToClipboard(inputValue),
                  pushWarning(new SuccessNotice("Copied to clipboard!"));
              }}
              className="text-gray-700/50 hover:text-black font-semibold hover:font-bold transition-all duration-200 text-sm mt-2 flex gap-1"
            >
              <span>Copy text</span>{" "}
              <div className="w-6">
                <ClipboardIcon />
              </div>
            </button>

            {/* Download button small screen */}
            {voiceoverStatus === "success" && (
              <div
                onClick={() => window.open(project.voice_over?.url)}
                className="sm:hidden cursor-pointer flex gap-1 items-center text-gray-700/50 hover:text-black font-semibold hover:font-bold mt-2"
              >
                <span className="text-sm">Download audio</span>
                <DownloadButton url={project.voice_over?.url} className="w-6" />
              </div>
            )}
          </div>

          {/* Save button */}
          {allowUpdate && (
            <button
              className="absolute top-5 right-5 sm:top-6 sm:right-6 py-2 text-sm sm:text-base px-4 bg-white transition-colors hover:bg-violet-100 rounded-md shadow-md ring-1 ring-gray-500/30 shadow-violet-400/50 mb-3"
              onClick={() => handleUpdateProject({ script: inputValue })}
            >
              Save
            </button>
          )}

          <div className="h-14 relative">
            {/* Component for generate button and voiceover player display */}
            <VoiceoverPlayerDisplay
              onClick={handleGenerateVoiceover}
              status={voiceoverStatus}
              title="Generate Voiceover"
              playerInfo={playerInfo}
              playerConfig={playerConfig}
            />

            {/* Download Button Large Screen */}
            {voiceoverStatus === "success" && (
              <DownloadButton
                url={project.voice_over?.url}
                className="max-sm:hidden absolute w-8 left-[105%] top-1/2 -translate-y-1/2"
              />
            )}
          </div>

          {/* Generate new Button */}
          {voiceoverStatus === "success" && (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              onClick={() => {
                removeParam(), playerConfig.pause();
              }}
              className="flex gap-2 items-center text-gray-500 hover:text-gray-900 transition-colors cursor-pointer"
            >
              <div className="w-8">
                <ChevronLeftIcon strokeWidth={2} />
              </div>
              <p className=" font-bold">Generate new voiceover</p>
            </motion.div>
          )}

          <div className="flex gap-4">
            {/* Pick voice button */}
            {voiceoverStatus === "idle" && isPickingVoice === false && (
              <motion.div
                layoutId="pick-voice-btn"
                onClick={() => setIsPickingVoice(true)}
                className="flex items-center py-1 pl-1 pr-2 gap-2 rounded-2xl bg-gray-200 cursor-pointer z-20"
              >
                <div
                  style={
                    selectedVoice && { backgroundImage: selectedVoice.gradient }
                  }
                  className="rounded-full w-5 h-5"
                ></div>
                <p className="text-sm text-gray-800">{selectedVoice?.name}</p>
              </motion.div>
            )}

            {/* View voiceover button */}
            {voiceoverStatus === "idle" && project.voice_over && (
              <motion.div
                layout
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, layout: { delay: 0 } }}
                onClick={() => {
                  addParam();
                }}
                className="flex gap-2 items-center text-gray-500 hover:text-violet-400 transition-colors cursor-pointer"
              >
                <p className=" font-bold">View voiceover</p>
                <div className="w-8">
                  <ChevronRightIcon strokeWidth={2} />
                </div>
              </motion.div>
            )}
          </div>

          {/* Word count */}
          {voiceoverStatus !== "success" && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={
                voiceoverStatus === "idle" ? { opacity: 1 } : { opacity: 0 }
              }
              className="text-sm text-gray-500"
            >
              {inputValue.length}/{charMax}
            </motion.p>
          )}
        </SharpModal>

        {project.voice_over && (
          <ReactPlayer
            ref={playerRef}
            url={project.voice_over.url}
            playing={playerInfo.playing}
            onProgress={playerConfig.handleProgress}
            onEnded={playerConfig.handleEnded}
            progressInterval={100}
            width={0}
            height={0}
            config={{
              file: {
                attributes: {
                  preload: "auto",
                },
                forceAudio: true,
              },
            }}
          />
        )}
      </div>
      <Warnings
        list={warnings}
        onClose={(item) => removeWarning(item)}
        onDurationEnd={(item) => removeWarning(item)}
      />
    </Layout>
  );
};

export default Project;
