import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { KeyboardEvent, useState } from "react";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

type AnimatedInputFieldProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  title: string;
  type?: "text" | "password";
  form: UseFormReturn<TFieldValues>;
  defaultValue?: true;
  animationReady: boolean;
  placeholder?: string;
  autofocus?: boolean;
  onSubmit: () => void;
};

const preventEnterKeyWithCallback = (
  event: KeyboardEvent<HTMLInputElement>,
  callback?: () => void,
) => {
  if (event.key === "Enter") {
    event.preventDefault();
    if (callback) {
      callback();
    }
  }
};

const AnimatedInputField = <T extends FieldValues>({
  name,
  title,
  type = "text",
  form,
  defaultValue,
  animationReady,
  autofocus = false,
  placeholder = "",
  onSubmit,
}: AnimatedInputFieldProps<T>): JSX.Element => {
  const [inputFocus, setInputFocus] = useState(false);

  return (
    <Controller
      name={name}
      control={form.control}
      render={({
        field: { onChange, onBlur, ref, value = "" },
        fieldState: { error },
      }) => (
        <div>
          <div className="rounded-2xl bg-white p-2 w-full">
            <div className="w-full h-full">
              <input
                type={type}
                name={name}
                ref={ref}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                onFocus={() => setInputFocus(true)}
                onBlur={() => {
                  onBlur();
                  setInputFocus(false);
                }}
                onKeyDown={(e) => preventEnterKeyWithCallback(e, onSubmit)}
                autoFocus={autofocus}
                autoCapitalize="off"
                autoComplete="off"
                spellCheck="false"
                autoCorrect="off"
                className="w-full h-full py-2 px-5 appearance-none outline-none rounded-md text-xl sm:text-2xl lg:text-2xl font-bold font-figTree bg-transparent text-gray-700/90 placeholder:font-bold placeholder:font-figTree placeholder:text-xl sm:placeholder:text-2xl lg:placeholder:text-2xl placeholder:black"
              />
            </div>
          </div>

          {error && (
            <div className="flex items-center mt-2">
              <ExclamationTriangleIcon
                className="w-4 h-4 fill-red-400"
                aria-hidden="true"
              />
              <span className="block ml-1.5 text-xs text-red-500">
                {error.message}
              </span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default AnimatedInputField;
