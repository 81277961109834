import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Warning, WarningLink, WarningType } from "../hooks/useWarnings";
import { Icon } from "../types/Icon";

type AlertProps = {
  message: string;
  style?: "default" | "styled";
  link?: WarningLink;
  closeable: boolean;
  onClose?: () => void;
  onDurationEnd?: () => void;
};

type AlertStylingProps = {
  style?: "default" | "styled";
  duration?: number;
  backgroundColor?: "bg-red-50" | string | "bg-green-50";
  blurColor?: string;
  styledBorder?: string;
  color:
    | "text-red-700"
    | string
    | "text-green-700"
    | "text-red-200"
    | "text-yellow-200";
  border?:
    | "border-red-400"
    | string
    | "border-green-400"
    | "border-red-700"
    | "border-yellow-600";
  fill: "fill-red-400" | any | "fill-green-400";
  stroke:
    | "stroke-red-500"
    | "stroke-yellow-500"
    | "stroke-green-500"
    | "stroke-red-50"
    | "stroke-yellow-50"
    | string;
  Icon: Icon;
};

type WarningsProps = {
  list: Array<Warning>;
  hasSidebar?: boolean;
  onClose?: (item: Warning) => void;
  onDurationEnd?: (item: Warning) => void;
};

const createAlertComponent =
  ({
    style = "default",
    duration,
    backgroundColor,
    blurColor,
    color,
    border,
    styledBorder,
    fill,
    stroke,
    Icon,
  }: AlertStylingProps): React.FC<AlertProps> =>
  ({ message, link, closeable, onClose, onDurationEnd }) => {
    useEffect(() => {
      if (duration) {
        const timer = setTimeout(() => {
          if (onDurationEnd !== undefined) {
            onDurationEnd();
          }
        }, duration);
        return () => clearTimeout(timer);
      }
    }, [duration]);
    return (
      <div className="relative">
        <div
          className={classNames(
            backgroundColor,
            border,
            {
              "p-4 shadow-lg border-l-4": style === "default",
            },
            {
              "pr-6 mx-2 pl-10 py-3 shadow-lg border backdrop-blur-sm rounded-2xl overflow-hidden":
                style === "styled",
            },
          )}
        >
          {style === "styled" && (
            <div
              className={`h-full absolute left-0 top-0 rounded-l-2xl w-8 ${styledBorder}`}
            >
              <div
                className={`absolute h-full w-full blur-lg opacity-50 ${blurColor}`}
              ></div>
            </div>
          )}
          <div
            className={classNames(
              { "justify-center": style === "styled" },
              "flex items-center",
            )}
          >
            {style !== "styled" && (
              <div className="flex-shrink-0">
                <Icon className={`w-5 h-5 ${fill}`} aria-hidden="true" />
              </div>
            )}
            <div
              className={classNames(
                {
                  "flex flex-col items-center text-center pr-5":
                    style === "styled",
                },
                "ml-3",
              )}
            >
              {style === "styled" && (
                <div className="flex-shrink-0 mx-auto">
                  <Icon className={`w-6 h-6 ${fill}`} aria-hidden="true" />
                </div>
              )}
              <span
                className={classNames(
                  "block",
                  color,
                  { "text-sm": style === "default" },
                  { "text-sm sm:text-base font-bold": style === "styled" },
                )}
              >
                <span>{message}</span>

                {!link ? null : (
                  <Link
                    reloadDocument={link.reload}
                    to={link.to}
                    className="ml-1.5"
                  >
                    <span
                      className={classNames(
                        "underline",
                        { "font-medium": style === "default" },
                        { "font-bold": style === "styled" },
                      )}
                    >
                      {link.title}
                    </span>
                  </Link>
                )}
              </span>
            </div>

            {!closeable ? null : (
              <button
                className="absolute top-0 bottom-0 right-0 px-4 py-2"
                onClick={onClose}
              >
                <XMarkIcon className={`h-6 w-6 ${stroke}`} />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

export const ErrorAlert = createAlertComponent({
  backgroundColor: "bg-red-50",
  color: "text-red-700",
  border: "border-red-400",
  fill: "fill-red-400",
  stroke: "stroke-red-500",
  Icon: XCircleIcon as Icon,
});

export const WarningAlert = createAlertComponent({
  backgroundColor: "bg-yellow-50",
  color: "text-yellow-700",
  border: "border-yellow-400",
  fill: "fill-yellow-400",
  stroke: "stroke-yellow-500",
  Icon: ExclamationTriangleIcon as Icon,
});

export const StyledWarningAlert = createAlertComponent({
  style: "styled",
  backgroundColor: "bg-slate-900/80",
  blurColor: "bg-yellow-200",
  border: "border-slate-300/40",
  styledBorder: "bg-yellow-300",
  color: "text-slate-100",
  fill: "fill-yellow-200",
  stroke: "stroke-yellow-50",
  Icon: ExclamationTriangleIcon as Icon,
});

export const StyledErrorAlert = createAlertComponent({
  style: "styled",
  backgroundColor: "bg-slate-900/80",
  blurColor: "bg-red-300",
  border: "border-slate-300/40",
  styledBorder: "bg-red-500",
  color: "text-slate-100",
  fill: "fill-red-400",
  stroke: "stroke-red-50",
  Icon: ExclamationTriangleIcon as Icon,
});

export const SuccessAlert = createAlertComponent({
  backgroundColor: "bg-green-50",
  duration: 3000,
  color: "text-green-700",
  border: "border-green-400",
  fill: "fill-green-400",
  stroke: "stroke-green-500",
  Icon: CheckCircleIcon as Icon,
});

export const StyledSuccessAlert = createAlertComponent({
  style: "styled",
  duration: 3000,
  blurColor: "bg-green-200",
  border: "border-slate-300/40",
  styledBorder: "bg-green-300",
  backgroundColor: "bg-slate-900/80",
  color: "text-slate-100",
  fill: "fill-green-300",
  stroke: "stroke-green-50",
  Icon: CheckCircleIcon as Icon,
});

const Warnings: React.FC<WarningsProps> = ({
  list,
  hasSidebar = true,
  onClose,
  onDurationEnd,
}) => {
  return (
    <div
      className={classNames(
        "fixed top-20 lg:top-4 left-2/4 translate-x-[-50%] w-full z-30",
        { "max-w-lg lg:max-w-3xl lg:pl-72": hasSidebar },
        { "max-w-xl": !hasSidebar },
      )}
    >
      <div className="flex flex-col space-y-2">
        {list
          .filter(
            (item) =>
              item.type === WarningType.Error && item.style === "default",
          )
          .map((item) => (
            <ErrorAlert
              key={item.id}
              message={item.message}
              link={item.link}
              closeable={item.closeable}
              onClose={() => onClose?.(item)}
            />
          ))}
        {list
          .filter(
            (item) =>
              item.type === WarningType.Default && item.style === "default",
          )
          .map((item) => (
            <WarningAlert
              key={item.id}
              message={item.message}
              link={item.link}
              closeable={item.closeable}
              onClose={() => onClose?.(item)}
            />
          ))}

        {list
          .filter(
            (item) =>
              item.type === WarningType.Error && item.style === "styled",
          )
          .map((item) => (
            <StyledErrorAlert
              key={item.id}
              message={item.message}
              link={item.link}
              closeable={item.closeable}
              onClose={() => onClose?.(item)}
            />
          ))}

        {list
          .filter(
            (item) =>
              item.type === WarningType.Default && item.style === "styled",
          )
          .map((item) => (
            <StyledWarningAlert
              key={item.id}
              message={item.message}
              link={item.link}
              closeable={item.closeable}
              onClose={() => onClose?.(item)}
            />
          ))}
        {list
          .filter(
            (item) =>
              item.type === WarningType.Success && item.style === "default",
          )
          .map((item) => (
            <SuccessAlert
              key={item.id}
              message={item.message}
              link={item.link}
              closeable={item.closeable}
              onClose={() => onClose?.(item)}
              onDurationEnd={() => onDurationEnd?.(item)}
            />
          ))}
        {list
          .filter(
            (item) =>
              item.type === WarningType.Success && item.style === "styled",
          )
          .map((item) => (
            <StyledSuccessAlert
              key={item.id}
              message={item.message}
              link={item.link}
              closeable={item.closeable}
              onClose={() => onClose?.(item)}
              onDurationEnd={() => onDurationEnd?.(item)}
            />
          ))}
      </div>
    </div>
  );
};

export default Warnings;
