export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function copyToClipboard(content: string) {
  try {
    await navigator.clipboard.writeText(content);
    console.log("File content copied to clipboard!");
  } catch (err) {
    console.error("Failed to copy text to clipboard:", err);
  }
}

export const scrollToSection = (
  sectionId: string,
  behavior: ScrollBehavior = "smooth",
) => {
  const element = document.getElementById(sectionId);
  if (element) {
    const yPosition = element.getBoundingClientRect().top + window.scrollY - 80;
    window.scrollTo({ top: yPosition, behavior: behavior });
  }
};

export const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
