import React from "react";
import { motion, MotionValue, useTransform } from "framer-motion";

interface AnimatedCheckIconProps {
  size: number; // Size of the icon
  strokeWidth: number; // Width of the stroke
  pathLength: MotionValue<number>; // MotionValue controlling the path length
  opacity: MotionValue<number>;
}

const AnimatedCheckIcon: React.FC<AnimatedCheckIconProps> = ({
  size,
  strokeWidth,
  pathLength,
  opacity,
}) => {
  // Define the path for the checkmark
  const pathData = "M2 10 L8 16 L18 6"; // Checkmark path (relative coordinates)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "block", margin: "auto" }}
    >
      <motion.path
        d={pathData}
        className="stroke-violet-500"
        strokeWidth={strokeWidth}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          pathLength,
          opacity,
        }}
      />
    </svg>
  );
};

export default AnimatedCheckIcon;
