import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../hooks/useProjects";
import { Project } from "../types/Project";
import { itemVariants } from "./Layout";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

const LatestProjects = () => {
  const { data, error, isLoading } = useProjects();
  const navigate = useNavigate();
  const [latestOpen, setLatestOpen] = useState(false);

  const projects: Project[] = useMemo(() => {
    if (data) {
      return [...data].sort((a, b) => b.timestamp - a.timestamp).slice(0, 3);
    } else {
      return [];
    }
  }, [data]);

  return (
    <>
      {projects.length > 0 && (
        <motion.li className="relative">
          <div
            onClick={() => setLatestOpen((prev) => !prev)}
            className="cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
          >
            {!latestOpen ? (
              <ChevronDownIcon
                aria-hidden="true"
                className="h-6 w-6 shrink-0"
              />
            ) : (
              <ChevronUpIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
            )}
            Latest
          </div>
          {latestOpen && (
            <motion.ul
              variants={dropdownVariants}
              initial="closed"
              animate="open"
              className="ml-9"
            >
              {projects.map((project) => (
                <motion.li
                  key={project.id}
                  variants={itemVariants}
                  onClick={() => navigate(`/projects/${project.id}`)}
                  className="text-gray-400/80 text-sm font-semibold leading-6 cursor-pointer hover:text-gray-200"
                >
                  {project.name}
                </motion.li>
              ))}
            </motion.ul>
          )}
        </motion.li>
      )}
    </>
  );
};

export default LatestProjects;

const dropdownVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
