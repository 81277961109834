import { Button } from "@headlessui/react";
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GradientBackground2 } from "../components/GradientBackgrounds";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import ProjectGridItem from "../components/ProjectGridItem";
import Warnings from "../components/Warnings";
import { useProjects } from "../hooks/useProjects";
import useSortItems from "../hooks/useSortItems";
import { SuccessNotice, useWarnings } from "../hooks/useWarnings";
import { SortBy, SortingOrder } from "../types";
import { callAPI } from "../utils/apiService";
import { copyToClipboard } from "../utils/functions";
import { Select } from "../components/selectCatalyst";

export default function Projects() {
  const [navigateToScript, setNavigateToScript] = useState(false);
  const [openSearchField, setOpenSearchField] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { warnings, pushWarning, removeWarning, clearWarnings } = useWarnings();

  useEffect(() => {
    if (inputRef.current) {
      if (openSearchField) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
    }
  });

  const { data, error, isLoading } = useProjects();
  const queryClient = useQueryClient();

  const { items, sortingConfig, setSortingConfig } = useSortItems({ data });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => callAPI(`/projects/${id}/remove`),
    onSuccess: (response) => {
      console.log("Successfully deleted project: " + response);
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
    onError: (err) => console.log("Error deleting project: " + err),
  });

  const handleDeleteProduct = (id: string) => {
    deleteMutation.mutate(id);
  };

  const handleCopy = (script: string) => {
    copyToClipboard(script);
    pushWarning(new SuccessNotice("Copied to clipboard!"));
  };

  if (error !== null) return <span>Something went wrong</span>;
  if (isLoading || data === undefined) return <Loading />;

  const projects = items;

  const containerVariants = {
    exit: {
      width: "100%",
      height: "100%",
      margin: 0,
      borderRadius: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: "easeIn",
        borderRadius: { delay: 0.2, duration: 0.2 },
        opacity: { delay: 0.6, duration: 0 },
      },
    },
  };

  const titleVariants = {
    exit: { scale: 0, opacity: 0, transition: { ease: "easeIn" } },
  };

  const inputVariants = {
    exit: {
      scaleX: 0,
      opacity: 0,
      transition: { delay: 0.15, ease: "easeIn" },
    },
  };

  return (
    <Layout>
      <div
        className={classNames(
          "w-full",
          { "h-screen overflow-hidden": navigateToScript },
          { "h-full": !navigateToScript },
        )}
      >
        <AnimatePresence onExitComplete={() => navigate(`/new?step=0`)}>
          {!navigateToScript && (
            <motion.div
              variants={containerVariants}
              exit="exit"
              className="z-10 mx-10 sm:mx-24 xl:mx-36 mt-24 mb-4 md:mb-8 lg:mt-8 relative aspect-[2/1] sm:aspect-[5/2] xl:aspect-[6/2] rounded-3xl overflow-hidden flex flex-col justify-center items-center gap-6 sm:gap-8"
            >
              <div className="inset-0 absolute w-full h-full bg-secondary -z-50"></div>
              <div className="absolute inset-0 w-full h-full -z-50">
                <GradientBackground2 />
              </div>
              <motion.h1
                variants={titleVariants}
                className="text-white text-2xl sm:text-4xl lg:text-5xl font-figTree font-semibold"
              >
                Create a new script
              </motion.h1>
              <motion.div
                variants={inputVariants}
                className="rounded-xl sm:rounded-2xl bg-white sm:p-1 w-3/5"
              >
                <input
                  onClick={() => setNavigateToScript(true)}
                  type="text"
                  placeholder="Bring your story to life"
                  className="w-full py-2 px-2 sm:px-5 appearance-none outline-none rounded-md text-sm sm:text-lg lg:text-xl font-bold font-figTree bg-transparent text-secondary/90 placeholder:font-bold placeholder:font-figTree placeholder:text-sm sm:placeholder:text-lg lg:placeholder:text-xl placeholder:black"
                />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="w-full mb-2 px-7 flex flex-wrap justify-between items-center">
          {/* Filter */}
          <div className="flex gap-2 sm:gap-4 items-center text-sm sm:text-base">
            <Select
              className="text-sm sm:text-base"
              name="sort_by"
              value={sortingConfig.sortBy}
              onChange={(e) =>
                setSortingConfig.sortBy(e.target.value as SortBy)
              }
            >
              {sortingConfig.sortByOptions.map((option, index) => (
                <option key={index} value={option}>
                  Sort by {option}
                </option>
              ))}
            </Select>
            <Button
              className="flex items-center hover:bg-gray-200 p-2 rounded-md"
              onClick={() => setSortingConfig.sortingOrder()}
            >
              {sortingConfig.sortingOrder === SortingOrder.Ascending ? (
                <BarsArrowUpIcon className="w-6" />
              ) : (
                <BarsArrowDownIcon className="w-6" />
              )}
              <span className="font-normal w-max">
                {sortingConfig.sortingOrder === SortingOrder.Ascending
                  ? sortingConfig.sortBy === SortBy.Date
                    ? "Oldest"
                    : "A-Z"
                  : sortingConfig.sortBy === SortBy.Date
                    ? "Newest"
                    : "Z-A"}
              </span>
            </Button>
          </div>

          {/* Search large screen*/}
          <motion.div
            onMouseEnter={() => setOpenSearchField(true)}
            onClick={() => setOpenSearchField(true)}
            className="relative px-2 py-1 rounded-lg overflow-hidden sm:flex gap-2 hidden"
            initial={{ backgroundColor: "#ffffff" }}
            animate={
              openSearchField
                ? { backgroundColor: "#f3f4f6" }
                : { backgroundColor: "#ffffff" }
            }
            transition={{ duration: 0.4 }}
          >
            <motion.div
              layout
              className="cursor-pointer w-6 py-1 text-gray-500"
            >
              <MagnifyingGlassIcon />
            </motion.div>
            <motion.input
              ref={inputRef}
              animate={openSearchField ? { width: "18rem" } : { width: "0rem" }}
              type="text"
              placeholder="Search..."
              className="text-gray-700 text-sm bg-transparent font-medium"
              onChange={(e) => setSortingConfig.searchValue(e.target.value)}
              onBlur={() => setOpenSearchField(false)}
            />
          </motion.div>

          {/* Search small screen*/}
          <div className="sm:hidden mt-3 relative px-2 py-1 rounded-lg w-full flex gap-2 bg-gray-100">
            <div className="cursor-pointer  w-6 py-1 text-gray-500">
              <MagnifyingGlassIcon />
            </div>
            <input
              type="text"
              placeholder="Search..."
              className="text-gray-700 bg-transparent font-medium text-sm"
              onChange={(e) => setSortingConfig.searchValue(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-6 pb-10">
          {projects.map((project) => (
            <ProjectGridItem
              key={project.id}
              project={project}
              deleteProject={handleDeleteProduct}
              handleCopy={handleCopy}
            />
          ))}
        </div>
      </div>
      <Warnings
        list={warnings}
        onClose={(item) => removeWarning(item)}
        onDurationEnd={(item) => removeWarning(item)}
      />
    </Layout>
  );
}
