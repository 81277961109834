import { CheckIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { motion, useAnimate } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useViewportWidth from "../hooks/useViewportWidth";
import {
  ConfigurationItem,
  ConfigurationOptions,
} from "../types/Configuration";
import { sleep } from "../utils/functions";
import AnimatedTitle from "./AnimatedTitle";
import { BorderAnimationButton } from "./Buttons";
import Select from "./Select";
import { TopicStepProps } from "./TopicStep";

type ConfigurationStepProps = TopicStepProps & {
  config: ConfigurationOptions;
};

type selectObject = {
  config: ConfigurationItem[];
  name: "topic" | "retention" | "hook" | "ending";
  title: string;
  description: string;
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2, // Controls the stagger effect timing
      delayChildren: 0.1, // Initial delay before starting the stagger
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const ConfigurationStep: FC<ConfigurationStepProps> = ({
  form,
  config,
  nextPage,
  pushWarning,
}) => {
  const navigate = useNavigate();
  const [scope, animate] = useAnimate();
  const [animationReady, setAnimationReady] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [selected, setSelected] = useState(0);
  const { isSmallWidth } = useViewportWidth({ maxWidth: 768 });
  const [canView, setCanView] = useState(0);

  useEffect(() => {
    if (animationReady) {
      const enterAnimation = async () => {
        animate(
          "#title",
          { y: 0, fontSize: isSmallWidth ? "7vw" : "4vw" },
          { duration: 0.3, delay: 0.3, ease: "easeOut" },
        );

        await sleep(300);
        setShowContent(true);
      };
      enterAnimation();
    }
  }, [animationReady]);

  const selectObjects: selectObject[] = [
    {
      config: config.hooks,
      name: "hook",
      title: "Hook",
      description:
        "Start strong. How will you grab attention? Choose a hook or let our AI create one.",
    },
    {
      config: config.retentions,
      name: "retention",
      title: "Retention",
      description:
        "This is the main part of your script. What emotion or atmosphere do you want to create?",
    },
    {
      config: config.callToActions,
      name: "ending",
      title: "Call to Action",
      description:
        "End with impact. How will you prompt action? Pick an ending or let our AI suggest one.",
    },
  ];

  const handleStepClick = (target: number) => {
    if (target <= canView) {
      setSelected(target);
    }
  };

  return (
    <div
      ref={scope}
      className="relative w-full min-h-screen flex flex-col gap-4 sm:gap-8 pt-10 lg:pt-10 pb-[10rem] items-center"
    >
      <div
        className="absolute left-4 right-4 sm:left-10 sm:right-10 max-lg:top-6 flex max-lg:justify-end items-center gap-1 text-white cursor-pointer"
        onClick={() => navigate(`?step=0`)}
      >
        <div className="w-8">
          <ChevronLeftIcon strokeWidth={2} />
        </div>
        <p className="hidden sm:block font-bold">Previous</p>
      </div>
      <motion.h1
        id="title"
        className="text-white font-figTree font-semibold"
        initial={{ opacity: 0, y: 100, filter: "blur(12px)", fontSize: "10vw" }}
        animate={{ opacity: 1, y: 130, filter: "blur(0px)", fontSize: "10vw" }}
        transition={{ duration: 0.4 }}
        onAnimationComplete={() => setAnimationReady(true)}
      >
        Configuration
      </motion.h1>
      <div id="text-container" className="-mt-4 scale-90 sm:scale-100">
        {animationReady && (
          <AnimatedTitle
            words={[
              { word: "Build" },
              { word: "your" },
              { word: "video" },
              { word: "script" },
              { word: "with" },
              { word: "the" },
              {
                word: "HRC",
                class:
                  "bg-gradient-to-r from-indigo-400 via-purple-500 to-fuchsia-500 inline-block text-transparent bg-clip-text",
              },
              { word: "framework." },
            ]}
            textClass="text-base sm:text-lgext-white font-semibold font-Figtree"
            cursorClass="h-8 bg-indigo-200"
          />
        )}
      </div>

      {showContent && (
        <motion.div
          className="flex-1 flex flex-col justify-end items-center w-full px-10 text-white"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {selectObjects.map((selectObject, index) => {
            const isSelected = index === selected && index <= canView;
            return (
              <motion.div
                key={selectObject.name}
                className="w-full sm:w-4/5 py-4 flex flex-col gap-4 justify-center relative shrink"
                variants={itemVariants}
                animate={
                  isSelected
                    ? { flexGrow: 1, height: "auto" }
                    : { flexGrow: 0, height: "fit-content" }
                }
              >
                {index < 2 && (
                  <div className="border-b border-violet-300/50 absolute w-full h-full inset-0"></div>
                )}
                {!isSelected && (
                  <div className="relative">
                    <motion.h2
                      layoutId={`h2-${selectObject.name}`}
                      style={{ scale: 0.7, opacity: 0.5 }}
                      className="font-figTree text-3xl font-bold text-center cursor-pointer"
                      onClick={() => handleStepClick(index)}
                      transition={{ duration: 0.4 }}
                    >
                      <span className="relative">
                        {selectObject.title}{" "}
                        {index < canView && (
                          <div className="absolute w-10 h-10 left-[110%] top-1/2 -translate-y-1/2">
                            <CheckIcon strokeWidth={3} />
                          </div>
                        )}
                      </span>
                    </motion.h2>
                  </div>
                )}
                {isSelected && (
                  <div className="mx-auto flex max-sm:flex-col max-sm:items-center justify-center gap-4 w-full">
                    <div className="flex-1">
                      <motion.h2
                        layoutId={`h2-${selectObject.name}`}
                        style={{ scale: 1, opacity: 1 }}
                        className="font-figTree text-3xl font-bold text-center mb-4"
                        transition={{ duration: 0.4 }}
                      >
                        {selectObject.title}
                      </motion.h2>
                      <motion.p
                        initial={{ y: 40, opacity: 0, filter: "blur(12px)" }}
                        animate={{ y: 0, opacity: 1, filter: "blur(0px)" }}
                        transition={{
                          delay: 0.3,
                          duration: 0.5,
                          ease: "easeOut",
                        }}
                        className="text-sm sm:text-base max-sm:text-center"
                      >
                        {selectObject.description}
                      </motion.p>
                    </div>

                    <motion.div className="flex-1 my-auto max-sm:w-full max-w-[20rem] lg:max-w-none">
                      <Select
                        form={form}
                        name={selectObject.name}
                        options={selectObject.config}
                        selectionCallback={() => {
                          setSelected((prev) => prev + 1),
                            setCanView(index + 1);
                        }}
                        category={selectObject.title}
                      />
                    </motion.div>
                  </div>
                )}
              </motion.div>
            );
          })}
          {selected === 3 && canView === 3 && (
            <motion.div
              className="flex flex-1 items-center text-white"
              initial={{ opacity: 0, scale: 0.5, y: 30 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.1, ease: "easeOut" }}
            >
              <BorderAnimationButton
                borderRadius="1.2rem"
                borderWidth={2}
                lineSize={130}
                type="submit"
                onPress={nextPage}
              >
                <div className="flex items-center gap-2 pr-4 py-2 ">
                  <div className="w-8">
                    <SparklesIcon />
                  </div>
                  <span className="font-bold text-2xl">Generate</span>
                </div>
              </BorderAnimationButton>
            </motion.div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default ConfigurationStep;
