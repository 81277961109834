import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { downloadFile } from "../utils/functions";

type DownloadButtonProps = {
  url?: string;
  className?: string;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ url, className }) => {
  return (
    <motion.div
      className={`${className || ""} sm:text-gray-500 cursor-pointer sm:hover:text-gray-900 sm:hover:scale-110 sm:transition-all`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.8, duration: 0.3, ease: "easeOut" }}
      onClick={() => window.open(url)}
    >
      <ArrowDownTrayIcon />
    </motion.div>
  );
};

export default DownloadButton;
