import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useHandleSearchParam } from "../hooks/useHandleSearchParam";
import { useAuth } from "../provider/authProvider";
import { callAPI } from "../utils/apiService";
import { CoverModal } from "./Modals";
import PasswordField from "./PasswordField";
import { BooleanRadio } from "./Radio";
import { FormSelect } from "./Select";
import TextField from "./TextField";
import { Button } from "./button";

const purposeOptions: string[] = [
  "Just for fun!",
  "Not sure yet",
  "Eventually for work",
];
const workOptions: string[] = [
  "Content Creator",
  "Marketing and Advertising",
  "Education",
  "E-commerce",
  "Corporate Training and Development",
  "Other",
];
const launchOptions: string[] = [
  "Yes, Im on the waitlist!",
  "I've heard about it.",
  "No, but now I do!",
];
const wishlistOptions: Array<{ bool: boolean; label: string }> = [
  { bool: true, label: "Yes" },
  { bool: false, label: "No" },
];

const CompleteAccount = () => {
  const { setToken } = useAuth();
  const [showWork, setShowWork] = useState(false);
  const { removeParam } = useHandleSearchParam("completeAccount");

  const accountSchema = yup.object().shape({
    name: yup.string().required(),
    age: yup
      .number()
      .min(13, "You must be at least 13 to create account")
      .required(),
    purpose: yup.string().required(),
    work: yup.string().optional(),
    familiarity: yup.string().required(),
    wishlist: yup.boolean().required(),
    password: yup.string().required(),
  });

  type FormFields = yup.InferType<typeof accountSchema>;

  const form = useForm<FormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(accountSchema),
  });

  const mutation = useMutation({
    mutationFn: ({
      name,
      age,
      purpose,
      work,
      familiarity,
      wishlist,
      password,
    }: FormFields) =>
      callAPI("/users/complete", {
        name,
        age,
        purpose,
        work,
        familiarity,
        wishlist,
        password,
      }),
    onSuccess: () => {
      callAPI<{ token: string }>("/users/sign-new-token").then((response) => {
        setToken(response.token);
        removeParam();
      });
    },
    onError: (err: Error) => {
      console.log(err);
    },
  });

  const handleSubmit = (data: FormFields) => {
    mutation.mutate(data);
  };

  useEffect(() => {
    if (form.getValues("purpose") === "Eventually for work") {
      setShowWork(true);
    } else {
      setShowWork(false);
    }
  }, [form.watch("purpose")]);

  return (
    <CoverModal>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Complete your account
            </h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Complete your account to receive full access to all features.
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Full name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <TextField name="name" title="Full Name" form={form} />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="age"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Age
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <TextField key="age" name="age" title="Age" form={form} />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <p className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Purpose of using the script generator
                </p>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <FormSelect
                    form={form}
                    options={purposeOptions}
                    name="purpose"
                  />
                </div>
              </div>

              {showWork && (
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <p className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    What's your field?
                  </p>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <FormSelect form={form} options={workOptions} name="work" />
                  </div>
                </div>
              )}

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <p className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Are you aware that we're launching a text-to-video AI
                  specifically for short-form content?
                </p>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <FormSelect
                    form={form}
                    options={launchOptions}
                    name="familiarity"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <p className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Add my account to the waitlist!
                </p>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <BooleanRadio
                    form={form}
                    name="wishlist"
                    options={wishlistOptions}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Password
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <PasswordField form={form} name="password" title="Password" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Button plain onClick={removeParam}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </CoverModal>
  );
};

export default CompleteAccount;
