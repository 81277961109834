import { SparklesIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import {
  motion,
  useAnimationFrame,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useRef, useState } from "react";
import { GradientBackground2 } from "./GradientBackgrounds";

type ButtonProps = {
  title: string;
  type?: "button" | "submit";
  disabled?: boolean;
  loading?: boolean;
  onPress?: () => void;
};

type ButtonStylingProps = {
  backgroundColor: "bg-primary-600" | "bg-red-600" | "bg-white";
  color: "text-white" | "text-gray-700";
  ring: "ring-primary-600" | "ring-red-600" | "ring-gray-300";
  hover: "bg-primary-700" | "bg-red-700" | "bg-gray-100";
  focus: "ring-primary-300" | "ring-red-300";
};

const createButtonComponent =
  ({
    backgroundColor,
    color,
    ring,
    hover,
    focus,
  }: ButtonStylingProps): React.FC<ButtonProps> =>
  ({ disabled = false, loading = false, onPress, title, type = "button" }) => (
    <div className="w-full sm:w-full">
      <button
        type={type}
        onClick={onPress}
        className={classNames(
          `relative w-full px-4 py-2 rounded-md shadow-md ${backgroundColor} hover:${hover} ring-1 ${ring} focus:ring-4 focus:${focus}`,
          { "pointer-events-none": disabled },
        )}
      >
        <div
          className={classNames(
            { "opacity-0": loading },
            "pointer-events-none",
          )}
        >
          <span
            className={classNames(
              { "text-opacity-60": disabled },
              `text-sm font-semibold ${color} pointer-events-none`,
            )}
          >
            {title}
          </span>
        </div>

        {!loading ? null : <div className="theme-spinner"></div>}
      </button>
    </div>
  );

export const PrimaryButton = createButtonComponent({
  backgroundColor: "bg-primary-600",
  color: "text-white",
  ring: "ring-primary-600",
  hover: "bg-primary-700",
  focus: "ring-primary-300",
});

export const SecondaryButton = createButtonComponent({
  backgroundColor: "bg-white",
  color: "text-gray-700",
  ring: "ring-gray-300",
  hover: "bg-gray-100",
  focus: "ring-primary-300",
});

export const GoogleButton = () => (
  <a
    href="#"
    className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
  >
    <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
      <path
        d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
        fill="#EA4335"
      />
      <path
        d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
        fill="#4285F4"
      />
      <path
        d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
        fill="#FBBC05"
      />
      <path
        d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
        fill="#34A853"
      />
    </svg>
    <span className="text-sm font-semibold leading-6">Google</span>
  </a>
);

export const NextButton: React.FC<ButtonProps> = ({
  title,
  onPress,
  type = "button",
  disabled,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <motion.button
      type={type}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onPress}
      className={classNames(
        "bg-transparent flex items-center p-2 rounded-full relative",
        { "pointer-events-none": disabled },
      )}
    >
      <motion.div
        animate={isHovering ? { width: "fit-content" } : { width: 0 }}
        transition={{ duration: 0.3, ease: "easeIn" }}
        className="overflow-hidden font-bold font-figTree"
      >
        {title}
      </motion.div>
      <motion.div className="w-10">
        <SparklesIcon />
      </motion.div>
    </motion.button>
  );
};

export const GenerateButton: React.FC<ButtonProps> = ({
  title,
  onPress,
  type = "button",
  disabled,
}) => {
  return (
    <motion.button
      onClick={onPress}
      type={type}
      disabled={disabled}
      className="hover:opacity-70 hover:scale-110 transition-all animated-border"
    >
      <div className="w-full h-full absolute px-3 flex items-center rounded-[30px] bg-gradient-to-br from-violet-800 to-third text-white overflow-hidden">
        <div className="absolute w-full h-full inset-0">
          <GradientBackground2 />
        </div>

        <div className="font-figTree text-2xl font-bold">{title}</div>
        <div className="w-16">
          <SparklesIcon />
        </div>
      </div>
    </motion.button>
  );
};

export const AlternativeGenerateButton: React.FC<ButtonProps> = ({
  title,
  onPress,
  type = "button",
  disabled,
}) => {
  return (
    <button
      onClick={onPress}
      disabled={disabled}
      type={type}
      className="hover:scale-110 hover:bg-violet-300 hover:text-third hover:border-violet-600 shadow-violet-400 hover:shadow-violet-600 transition-all duration-300 px-4 py-2 rounded-3xl border border-violet-300/50 flex  gap-2 items-center bg-third/10 backdrop-blur-sm shadow-md text-violet-100"
    >
      <div className="font-figTree text-2xl font-bold">{title}</div>
      <div className="w-16">
        <SparklesIcon />
      </div>
    </button>
  );
};

type BorderAnimationButtonProps = Partial<ButtonProps> & {
  children: React.ReactNode;
  borderRadius?: string;
  borderWidth?: number;
  lineSize?: number | string;
};

export const BorderAnimationButton: React.FC<BorderAnimationButtonProps> = ({
  children,
  onPress,
  type = "button",
  borderRadius = "2rem",
  borderWidth = 3,
  lineSize = 160,
}) => {
  return (
    <div className="relative">
      <div
        style={{
          borderRadius: borderRadius,
        }}
        className="absolute -inset-0 bg-gradient-to-tl from-violet-600 via-violet-400 to-violet-200 opacity-50 blur"
      ></div>

      <button
        style={{
          borderRadius: borderRadius,
          padding: `${borderWidth}px`,
        }}
        onClick={onPress}
        type={type}
        className={`relative bg-transparent h-fit w-fit overflow-hidden`}
      >
        <div
          style={{ borderRadius: `calc(${borderRadius} * 0.96)` }}
          className="absolute inset-0"
        >
          <BorderAnimation>
            <div
              style={{ width: lineSize, height: lineSize }}
              className={`h-[${lineSize}] w-[${lineSize}] opacity-[0.8] bg-[radial-gradient(#c4b5fd_35%,transparent_65%)]`}
            />
          </BorderAnimation>
        </div>
        <div
          className="relative bg-gradient-to-r from-violet-800 via-violet-500 to-violet-300 backdrop-blur-xl text-white font-bold font-figTree flex items-center w-full h-full px-4 py-3 antialiased"
          style={{
            borderRadius: `calc(${borderRadius} * 0.96)`,
          }}
        >
          {children}
        </div>
      </button>
    </div>
  );
};

type BorderAnimationProps = {
  children: React.ReactNode;
  duration?: number;
  rx?: string;
  ry?: string;
};

const BorderAnimation: React.FC<BorderAnimationProps> = ({
  children,
  duration = 4000,
  rx,
  ry,
}) => {
  const pathRef = useRef<any>();
  const progress = useMotionValue<number>(0);

  useAnimationFrame((time) => {
    const length = pathRef.current?.getTotalLength();
    if (length) {
      const pxPerMillisecond = length / duration;
      progress.set((time * pxPerMillisecond) % length);
    }
  });

  const x = useTransform(
    progress,
    (val) => pathRef.current?.getPointAtLength(val).x,
  );
  const y = useTransform(
    progress,
    (val) => pathRef.current?.getPointAtLength(val).y,
  );

  const transform = useMotionTemplate`translateX(${x}px) translateY(${y}px) translateX(-50%) translateY(-50%)`;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="absolute h-full w-full"
        width="100%"
        height="100%"
      >
        <rect
          fill="none"
          width="100%"
          height="100%"
          rx={rx}
          ry={ry}
          ref={pathRef}
        />
      </svg>
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "inline-block",
          transform,
        }}
      >
        {children}
      </motion.div>
    </>
  );
};
