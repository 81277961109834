import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import useExplanationAnimation from "../hooks/useExplanationAnimation";
import { useIsLandscape } from "../hooks/useIsLandscape";
import CircularProgress from "./CircularProgress";
import AnimatedCheckIcon from "./AnimatedCheckIcon";
import useViewportHeight from "../hooks/useViewportHeight";

const ExplanationSection = () => {
  const ref = useRef(null);

  const isLandscape = useIsLandscape();
  const { isSmallHeight: isXsHeight } = useViewportHeight({
    maxHeight: 700,
    phoneOnly: true,
  });
  const { isSmallHeight } = useViewportHeight({
    maxHeight: 850,
    phoneOnly: true,
  });

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });

  const {
    x: hookX,
    y: hookY,
    z: hookZ,
    opacity: hookOpacity,
    bg: hookBg,
    shadow: hookShadow,
  } = useExplanationAnimation({ scrollYProgress, index: 0 });
  const {
    x: retX,
    y: retY,
    z: retZ,
    opacity: retOpacity,
    bg: retBg,
    shadow: retShadow,
  } = useExplanationAnimation({ scrollYProgress, index: 1 });
  const {
    x: callX,
    y: callY,
    z: callZ,
    opacity: callOpacity,
    bg: callBg,
    shadow: callShadow,
  } = useExplanationAnimation({ scrollYProgress, index: 2 });

  const progressBarWidth = useTransform(
    scrollYProgress,
    [0, 0.95],
    ["0%", "100%"],
  );

  const circlePathlength = useTransform(scrollYProgress, [0, 0.95], [0, 1]);

  const checkPathLength = useTransform(scrollYProgress, [0.95, 1], [0, 1]);

  const checkOpacity = useTransform(scrollYProgress, [0.94, 0.95], [0, 1]);

  const revealTextOpacity = useTransform(scrollYProgress, [0.9, 0.95], [1, 0]);

  const progressBarOpacity = useTransform(scrollYProgress, [0.9, 0.95], [1, 0]);
  const blurFilter = useTransform(
    scrollYProgress,
    [0, 0.05, 0.25, 0.3, 0.35, 0.55, 0.6, 0.65, 0.85, 0.9],
    [
      "blur(0px)",
      "blur(4px)",
      "blur(4px)",
      "blur(0px)",
      "blur(4px)",
      "blur(4px)",
      "blur(0px)",
      "blur(4px)",
      "blur(4px)",
      "blur(0px)",
    ],
  );
  const filterColor = useTransform(
    scrollYProgress,
    [0, 0.05, 0.25, 0.3, 0.35, 0.55, 0.6, 0.65, 0.85, 0.9],
    [
      "rgba(255, 255, 255, .0)",
      "rgba(31, 41, 55, .2)",
      "rgba(31, 41, 55, .2)",
      "rgba(255, 255, 255, .0)",
      "rgba(31, 41, 55, .2)",
      "rgba(31, 41, 55, .2)",
      "rgba(255, 255, 255, .0)",
      "rgba(31, 41, 55, .2)",
      "rgba(31, 41, 55, .2)",
      "rgba(255, 255, 255, .0)",
    ],
  );

  return (
    <>
      <div
        ref={ref}
        id="explanation"
        className="mx-auto mt-16 max-w-7xl sm:px-6 sm:mt-40 lg:px-8 h-[500vh] relative bg-gradient-to-b from-white via-white via-90% to-transparent"
      >
        {(isXsHeight || isLandscape) && (
          <motion.div
            style={{ width: progressBarWidth, opacity: progressBarOpacity }}
            className="h-2 z-50 sticky top-0 left-0 bg-violet-500"
          />
        )}
        <div
          className={classNames(
            { "top-16 sm:top-28": !isLandscape },
            { "top-4 lg:top-28": isLandscape },
            "sticky overflow-hidden h-screen ",
          )}
        >
          <motion.div
            className="fixed inset-0 h-screen w-screen z-10 pointer-events-none"
            style={{ backdropFilter: blurFilter, backgroundColor: filterColor }}
          />
          <div className="mx-auto max-w-2xl text-center max-sm:px-6">
            <h2 className="text-base font-semibold leading-7 text-violet-600">
              HRC Explanation
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              What is the HRC framework
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              The HRC framework is a method for crafting engaging scripts,
              particularly suited for short-form content. It stands for Hook,
              Retention, and Call to Action. Here’s a concise overview:
            </p>
          </div>
          <div className="grid grid-cols-3 mx-auto w-fit mt-6">
            {features.map((feature, index) => {
              let style;
              let descriptionStyle;
              switch (index) {
                case 0:
                  style = { x: hookX, y: hookY, zIndex: hookZ };
                  descriptionStyle = {
                    opacity: hookOpacity,
                    backgroundColor: hookBg,
                    boxShadow: hookShadow,
                  };
                  break;
                case 1:
                  style = { x: retX, y: retY, zIndex: retZ };
                  descriptionStyle = {
                    opacity: retOpacity,
                    backgroundColor: retBg,
                    boxShadow: retShadow,
                  };
                  break;
                case 2:
                  style = { x: callX, y: callY, zIndex: callZ };
                  descriptionStyle = {
                    opacity: callOpacity,
                    backgroundColor: callBg,
                    boxShadow: callShadow,
                  };
              }
              return (
                <motion.div
                  key={feature.name}
                  className="relative sm:px-8 w-28 sm:w-48"
                  style={style}
                >
                  <dt className="text-sm sm:text-base font-semibold leading-7 text-gray-900 flex flex-col gap-2 items-center">
                    <div className=" flex h-10 w-10 items-center justify-center rounded-lg bg-violet-600">
                      <feature.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-white"
                      />
                    </div>
                    <span className="">{feature.name}</span>
                  </dt>
                  <motion.dd
                    style={descriptionStyle}
                    className="relative -z-10 -left-[6.5rem] sm:-left-24 w-80 -top-24 pt-28 px-8 pb-8 text-sm sm:text-base text-center leading-6 sm:leading-7 text-gray-600 rounded-xl "
                  >
                    {feature.description}
                  </motion.dd>
                </motion.div>
              );
            })}
          </div>
          {!isXsHeight && !isLandscape && (
            <div
              className={classNames(
                { "top-[65vh]": isSmallHeight },
                "absolute top-[50vh] left-1/2 -translate-x-1/2",
              )}
            >
              <CircularProgress
                strokeWidth={8}
                size={isSmallHeight ? 150 : 200}
                pathLength={circlePathlength}
              >
                <motion.div
                  className="text-gray-700 font-semibold"
                  style={{ opacity: revealTextOpacity }}
                >
                  Scroll to reveal
                </motion.div>
                <div className="absolute w-full h-full inset-0 grid place-content-center">
                  <AnimatedCheckIcon
                    pathLength={checkPathLength}
                    opacity={checkOpacity}
                    strokeWidth={1.5}
                    size={100}
                  />
                </div>
              </CircularProgress>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExplanationSection;

const features = [
  {
    name: "Hook",
    description: (
      <span>
        This is the initial part of your script, meant to{" "}
        <strong className="font-bold">
          capture the viewer's attention right away.
        </strong>{" "}
        The hook should be compelling and relevant, drawing the audience in{" "}
        <strong className="font-bold">within the first few seconds.</strong> It
        might include a striking question, an intriguing statement, or a
        visually appealing element.
      </span>
    ),
    icon: CloudArrowUpIcon,
  },
  {
    name: "Retention",
    description: (
      <span>
        After the hook, the focus shifts to keeping the{" "}
        <strong className="font-bold">viewer's interest.</strong> This section
        includes delivering valuable content,{" "}
        <strong className="font-bold">building curiosity,</strong> or telling a
        story that resonates. The aim is to keep the audience engaged and
        prevent them from losing{" "}
        <strong className="font-bold">interest or clicking away.</strong>
      </span>
    ),
    icon: LockClosedIcon,
  },
  {
    name: "Call to action",
    description: (
      <span>
        The final part of the script should provide a{" "}
        <strong className="font-bold">clear ending</strong> and encourage the
        viewer to take a specific action{" "}
        <strong className="font-bold">(CTA)</strong>. This could include an
        invitation to like, share, subscribe, visit a website, or make a
        purchase. <strong className="font-bold">Alternatively, the AI</strong>{" "}
        can end with a question or conclusion that prompts further thought or
        engagement.
      </span>
    ),
    icon: ArrowPathIcon,
  },
];
